<template>
<div class="stats-container">
	<div v-if="isLoading" class="loading-state">
	<div class="loader"></div>
	<p>Cargando estadísticas...</p>
	</div>

	<div v-else-if="error" class="error-state">
	<v-icon icon="mdi-alert-circle" size="64" color="error"></v-icon>
	<h3>{{ error }}</h3>
	<v-btn color="primary" @click="fetchPlayerProfile">
		Reintentar
	</v-btn>
	</div>

	<div v-else>
	<div v-if="hasPlayerData" class="stats-content">
		<!-- Card por cada torneo -->
		<div
		v-for="(t_info_array, tournamentId) in player_info_by_tournament"
		:key="tournamentId"
		class="tournament-card"
		>
		<div class="tournament-header">
			<h2 class="tournament-name">
			<span class="trophy-icon">🏆</span>
			{{ t_info_array[0].tournament_name }}
			</h2>
		</div>

		<!-- Información por fase -->
		<div 
			v-for="(info_fase, fase) in t_info_array" 
			:key="fase" 
			class="phase-container"
		>
			<!-- Fase de Grupos -->
			<div v-if="fase === 'group'" class="group-phase">
			<div class="phase-header">
				<span class="phase-icon">👥</span>
				<h3>Fase de Grupos</h3>
			</div>
			
			<div class="stats-grid">
				<div class="stat-item">
				<span class="stat-label">Estado</span>
				<span :class="['stat-value', getClasificacionClass(info_fase[0].clasifico)]">
					{{ getClasificacionText(info_fase[0].clasifico) }}
				</span>
				</div>
				
				<div class="stat-item">
				<span class="stat-label">Posición</span>
				<span class="stat-value position">
					#{{ info_fase[0].position_in_the_group }}
				</span>
				</div>
			</div>
			</div>

			<!-- Fase de Playoffs -->
			<div v-if="fase === 'playoff'" class="playoff-phase">
			<div class="phase-header">
				<span class="phase-icon">🎯</span>
				<h3>Playoffs</h3>
			</div>
			
			<div class="matches-container">
				<div 
				v-for="(match, index) in info_fase" 
				:key="index"
				class="match-card"
				:class="{ 'match-won': match.gano, 'match-lost': !match.gano }"
				>
				<div class="match-info">
					<span class="match-result">
					{{ match.gano ? '¡Victoria!' : 'Derrota' }}
					</span>
					<span class="match-rival">
					vs {{ getRivalName(match.rival_id, match) }}
					</span>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	</div>

	<!-- Estado vacío -->
	<div v-else class="empty-state">
		<div class="empty-state-content">
		<v-icon icon="mdi-trophy-outline" size="64" color="grey-lighten-1"></v-icon>
		<h3>No hay torneos disponibles</h3>
		<p>Este jugador aún no ha participado en ningún torneo</p>
		</div>
	</div>
	</div>
</div>
</template>

<script>
import axios from 'axios';
import { useAuthStore } from "@/store/auth";

export default {
name: 'ShowPlayer',

props: {
	teamId: {
	type: String,
	required: true
	}
},

data() {
	return {
	player_info_by_tournament: null,
	player_name: '',
	isLoading: true,
	error: null
	};
},

computed: {
	hasPlayerData() {
	return this.player_info_by_tournament && 
			Object.keys(this.player_info_by_tournament).length > 0;
	}
},

created() {
	this.fetchPlayerProfile();
},

methods: {

	getClasificacionClass(clasifico) {
		return clasifico == "1" ? 'qualified' : 'not-qualified';
	},
	getClasificacionText(clasifico) {
		return clasifico == "1" ? 'Clasificado' : 'No Clasificado';
	},


	async fetchPlayerProfile() {
	this.isLoading = true;
	this.error = null;
	try {
		const token = useAuthStore().token;
		const response = await axios.get(
		`${process.env.VUE_APP_API_ENDPOINT}showTeamAsPlayer/${this.teamId}`,
		{
			headers: {
			'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
			'Content-Type': 'application/json'
			},
		}
		);
		this.player_info_by_tournament = response.data;
	} catch (error) {
		console.error('Error al cargar los datos:', error);
		this.error = 'Error al cargar las estadísticas del jugador';
	} finally {
		this.isLoading = false;
	}
	},

	getRivalName(id, a) {
	if (a.rival_id === 0) return "BYE";
	return a.rival_id === a.id_team_1 ? a.name_team_1 : a.name_team_2;
	}
}
};
</script>

<style scoped>
.stats-container {
max-width: 1200px;
margin: 2rem auto;
padding: 0 1rem;
}

.stats-content {
display: grid;
gap: 2rem;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.tournament-card {
background: white;
border-radius: 1rem;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
overflow: hidden;
transition: transform 0.2s ease;
}

.tournament-card:hover {
transform: translateY(-4px);
}

.tournament-header {
background: linear-gradient(135deg, #2563eb, #1d4ed8);
color: white;
padding: 1.5rem;
}

.tournament-name {
font-size: 1.5rem;
font-weight: 600;
margin: 0;
display: flex;
align-items: center;
gap: 0.5rem;
}

.trophy-icon {
font-size: 1.25rem;
}

.phase-container {
padding: 1.5rem;
}

.phase-header {
display: flex;
align-items: center;
gap: 0.5rem;
margin-bottom: 1rem;
}

.phase-icon {
font-size: 1.25rem;
}

.stats-grid {
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 1rem;
margin-top: 1rem;
}

.stat-item {
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem;
background: #f8fafc;
border-radius: 0.5rem;
}

.stat-label {
font-size: 0.875rem;
color: #64748b;
margin-bottom: 0.5rem;
}

.stat-value {
font-size: 1.125rem;
font-weight: 600;
}

.qualified {
color: #059669;
}

.not-qualified {
color: #dc2626;
}

.matches-container {
display: flex;
flex-direction: column;
gap: 1rem;
}

.match-card {
padding: 1rem;
border-radius: 0.5rem;
border: 1px solid #e2e8f0;
transition: all 0.2s ease;
}

.match-won {
border-left: 4px solid #059669;
background: #f0fdf4;
}

.match-lost {
border-left: 4px solid #dc2626;
background: #fef2f2;
}

.match-info {
display: flex;
justify-content: space-between;
align-items: center;
}

.match-result {
font-weight: 600;
}

.match-rival {
color: #64748b;
}

.loading-state {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 400px;
gap: 1rem;
}

.loader {
width: 40px;
height: 40px;
border: 4px solid #f3f3f3;
border-top: 4px solid #2563eb;
border-radius: 50%;
animation: spin 1s linear infinite;
}

.error-state {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 400px;
gap: 1rem;
text-align: center;
color: #dc2626;
}

.empty-state {
display: flex;
justify-content: center;
align-items: center;
min-height: 400px;
background-color: white;
border-radius: 1rem;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.empty-state-content {
text-align: center;
padding: 2rem;
}

.empty-state-content h3 {
margin: 1rem 0;
font-size: 1.5rem;
color: #374151;
}

.empty-state-content p {
color: #6B7280;
margin: 0;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

@media (max-width: 640px) {
.stats-content {
	grid-template-columns: 1fr;
}

.stats-grid {
	grid-template-columns: 1fr;
}

.match-info {
	flex-direction: column;
	gap: 0.5rem;
	align-items: flex-start;
}
}
</style>