<template>
  <v-container class="team-container">
    <!-- Team Header Card -->
    <v-card class="header-card mb-6" elevation="3">
      <div class="header-gradient">
        <v-card-title class="d-flex flex-column flex-md-row justify-space-between align-center py-6 px-6">
          <div class="d-flex align-center">
            <v-icon size="36" style="color: white;" class="mr-3">mdi-shield</v-icon>
            <div>
              <h2 class="text-h4 mb-1" style="color: white;font-weight: bold">{{ team_name }}</h2>
              <span style="color: lightsteelblue;">{{ jugadores.length }} Jugadores</span>
            </div>
          </div>
          <div v-if="deuda" class="debt-badge mt-4 mt-md-0">
            <v-icon style="color: #FFCDD2;" class="mr-2">mdi-alert-circle</v-icon>
            <div>
              <div class="text-caption" style="color: #FFCDD2;">Saldo Pendiente</div>
              <div class="text-h6" style="color: white;font-weight: bold">{{ formatCurrency(deuda) }}</div>
            </div>
          </div>
        </v-card-title>
      </div>
    </v-card>

    <!-- Players Card -->
    <v-card class="mb-6" elevation="2">
      <v-card-title class="py-4 d-flex align-center">
        <v-icon color="primary" class="mr-2">mdi-account-group</v-icon>
        <span class="text-h5">Mi Equipo</span>
      </v-card-title>
      <v-card-text class="px-4">
        <v-row>
          <v-col v-for="(jugador, index) in jugadores"
                 :key="index"
                 cols="12" md="6">
            <v-card
                @click="goToPlayer(jugador.id)"
                class="player-card"
                elevation="1"
                variant="outlined"
            >
              <v-card-item>
                <template v-slot:prepend>
                  <v-avatar color="primary" size="40">
                    {{ getInitials(jugador.name) }}
                  </v-avatar>
                </template>
                <v-card-title>{{ jugador.name }}</v-card-title>
                <template v-slot:append>
                  <v-icon icon="mdi-chevron-right" color="primary"></v-icon>
                </template>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Results Card -->
    <v-card v-if="sortedResultMatches.length" class="mb-6" elevation="2">
      <v-card-title class="py-4 d-flex align-center">
        <v-icon color="primary" class="mr-2">mdi-trophy</v-icon>
        <span class="text-h5">Mis Resultados</span>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="match-list">
          <v-card
              v-for="(match, index) in sortedResultMatches"
              :key="index"
              class="match-card mb-3"
              variant="outlined"
          >
            <div class="match-content pa-4">
              <div class="match-date">
                <v-chip color="primary" variant="tonal" size="small">
                  Match Day {{ match.number_of_date }}
                </v-chip>
              </div>
              <div class="match-teams">
                <div :class="['team', isWinner(match.name_team_1, match.result, match) ? 'winner' : '']">
                  {{ match.name_team_1 }}
                </div>
                <div class="score-display">
                  <span class="score">{{ getScore(match.result, 0) }}</span>
                  <span class="score-divider">-</span>
                  <span class="score">{{ getScore(match.result, 1) }}</span>
                </div>
                <div :class="['team', isWinner(match.name_team_2, match.result, match) ? 'winner' : '']">
                  {{ match.name_team_2 }}
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <!-- Next Matches Card -->
    <v-card v-if="sortedNextMatches.length" elevation="2">
      <v-card-title class="py-4 d-flex align-center">
        <v-icon color="primary" class="mr-2">mdi-calendar-clock</v-icon>
        <span class="text-h5">Próximos Partidos</span>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="match-list">
          <v-card
              v-for="(match, index) in sortedNextMatches"
              :key="index"
              class="match-card upcoming-match mb-3"
              variant="outlined"
          >
            <div class="match-content pa-4">
              <div class="match-date">
                <v-chip color="primary" variant="tonal" size="small">
                  Match Day {{ match.number_of_date }}
                </v-chip>
              </div>
              <div class="match-teams">
                <div class="team">{{ match.name_team_1 }}</div>
                <div class="vs-badge">
                  <v-chip color="primary" variant="tonal">VS</v-chip>
                </div>
                <div class="team">{{ match.name_team_2 }}</div>
              </div>
            </div>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useAuthStore } from "@/store/auth";
import { useTeamStore } from "@/store/team";
import { useTournamentDetailsStore } from "@/store/tournamentDetails";
import { useAccountingStore } from "@/store/accountingRecord";

export default {
  name: 'ShowTeam',

  props: {
    teamId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      jugadores: [],
      team_name: "",
      is_able: false,
      resultMatches: [],
      nextMatches: [],
      teamStore: useTeamStore(),
      deuda: null
    };
  },

  computed: {
    sortedResultMatches() {
      return [...this.resultMatches].sort((a, b) => b.number_of_date - a.number_of_date);
    },
    sortedNextMatches() {
      return [...this.nextMatches].sort((a, b) => b.number_of_date - a.number_of_date);
    }
  },

  created() {
    this.fetchTeamDetails();
    this.fetchAble();
    const tournamentDetailsStore = useTournamentDetailsStore();
    if(tournamentDetailsStore.idTournament) {
      this.fetchDebtTeamTournament(tournamentDetailsStore.idTournament);
    }
  },

  methods: {
    formatCurrency(amount) {
      return new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS'
      }).format(amount);
    },

    getInitials(name) {
      return name
          .split(' ')
          .map(word => word[0])
          .join('')
          .toUpperCase()
          .slice(0, 2);
    },

    formatDate(match) {
      return match.number_of_date ? `Date ${match.number_of_date}` : '';
    },

    getScore(result, index) {
      if (!result) return '';
      const scores = result.split('-');
      return scores[index] || '';
    },

    isWinner(teamName, result, match) {
      if (!result) return false;
      const [score1, score2] = result.split('-').map(Number);

      if (isNaN(score1) || isNaN(score2)) return false;

      if (teamName === match.name_team_1) {
        return score1 > score2;
      } else if (teamName === match.name_team_2) {
        return score2 > score1;
      }
      return false;
    },

    async fetchAble() {
      try {
        const authStore = useAuthStore();
        this.is_able = authStore.isAble;

        const tournamentDetails = useTournamentDetailsStore();
        if(tournamentDetails.idTournament === undefined || tournamentDetails.idTournament === null) {return;}
        const matchesByTeam = await this.teamStore.dispatchGetMatchesByTeamTournament(
            this.teamId,
        );

        if(matchesByTeam.content.phase_2) {
          this.resultMatches = [
            ...matchesByTeam.content.phase_1.filter(match => match.result !== null && match.result !== ""),
            ...matchesByTeam.content.phase_2.filter(match => match.result !== null && match.result !== "")
          ];
          this.nextMatches = [
            ...matchesByTeam.content.phase_1.filter(match => match.result == null || match.result === ""),
            ...matchesByTeam.content.phase_2.filter(match => match.result == null || match.result === "")
          ];
        } else {
          this.resultMatches = [
            ...matchesByTeam.content.phase_1.filter(match => match.result !== null && match.result !== ""),
          ];
          this.nextMatches = [
            ...matchesByTeam.content.phase_1.filter(match => match.result == null || match.result === ""),
          ];
        }
      } catch (error) {
        console.error('Error checking permissions:', error);
        this.is_able = false;
      }
    },

    async fetchTeamDetails() {
      try {
        const response = await this.teamStore.dispatchGetTeamPlayers(this.teamId);
        if (response.success) {
          this.team_name = response.content.name;
          this.jugadores = response.content.players || [];
        }
      } catch (error) {
        console.error('Error fetching team details:', error);
      }
    },

    async fetchDebtTeamTournament(idTournament) {
      try {
        const accountingStore = useAccountingStore();
        const response = await accountingStore.dispatchGetDebtsTeam(
            this.teamId,
            idTournament
        );
        if (response.success && response.content.length > 0) {
          this.deuda = response.content[0].deuda;
        }
      } catch (error) {
        console.error('Error fetching team details:', error);
      }
    },

    goToUpdateTeam() {
      if (this.teamId) {
        this.$router.push(`/updateTeam/${this.teamId}`);
      }
    },

    goToPlayer(id) {
      this.$router.push({
        name: 'ShowPlayer',
        params: {playerId: id}
      });
    },

    goToUpdateTeamPlayer(id) {
      this.$router.push({
        name: 'ShowTeamAsPlayer',
        params: {teamId: id}
      });
    }
  }
};
</script>

<style scoped>
.team-container {
  max-width: 1200px;
  margin: 0 auto;
}

.header-gradient {
  background: linear-gradient(135deg, rgb(var(--v-theme-primary)) 0%, rgb(var(--v-theme-primary-darken-1)) 100%);
}

.debt-badge {
  background: rgba(244, 67, 54, 0.2);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.player-card {
  transition: all 0.2s ease;
  cursor: pointer;
}

.player-card:hover {
  transform: translateX(4px);
  background: rgb(var(--v-theme-primary-lighten-5));
}

.match-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.match-card {
  border: 1px solid rgba(var(--v-theme-primary), 0.12);
}

.match-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.match-teams {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
  align-items: center;
  text-align: center;
}

.team {
  font-size: 1.1rem;
  font-weight: 500;
}

.team.winner {
  color: rgb(var(--v-theme-success));
  font-weight: 700;
  font-size: 1.2rem;
}

.score-display {
  background: rgb(var(--v-theme-primary-lighten-5));
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.score {
  font-size: 1.25rem;
  font-weight: 700;
  color: rgb(var(--v-theme-primary));
  min-width: 24px;
}

.score-divider {
  color: rgb(var(--v-theme-primary));
  font-weight: 500;
}

.upcoming-match .match-teams {
  grid-template-columns: 1fr auto 1fr;
}

@media (max-width: 600px) {
  .match-teams {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .score-display {
    justify-content: center;
  }

  .upcoming-match .match-teams {
    grid-template-columns: 1fr;
  }
}
</style>