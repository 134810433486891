<template>
    <div class="match-details">
      <div class="card">
        <!-- Header -->
        <div class="card-header">
          <h2 class="card-title">
            <span class="trophy">🏆</span>
            Match Details
          </h2>
        </div>
  
        <!-- Loading State -->
        <div v-if="!match" class="card-content">
          <div class="loading-state">
            <p class="text-muted">Loading match details...</p>
          </div>
        </div>
  
        <!-- Match Content -->
        <div v-else class="card-content">
          <!-- Teams and Score -->
          <div class="teams-container">
            <div class="team-section">
              <h3 class="team-name">{{ match.name_team_1 }}</h3>
              <div class="score">
                {{ match.stats?.res ? match.stats.res.split('-')[0] : '-' }}
              </div>
            </div>
            
            <div class="vs-section">
              <span class="vs-text">vs</span>
            </div>
            
            <div class="team-section">
              <h3 class="team-name">{{ match.name_team_2 }}</h3>
              <div class="score">
                {{ match.stats?.res ? match.stats.res.split('-')[1] : '-' }}
              </div>
            </div>
          </div>
  
          <!-- Sport-specific stats -->
          <div v-if="match.stats?.sport === 'futbol'" class="stats-container">
            <!-- Team 1 Stats -->
            <div class="team-stats">
              <h4 class="stats-title">{{ match.name_team_1 }} Statistics</h4>
              
              <!-- Goals -->
              <div v-if="match.stats.goles_1_names?.length" class="stat-section">
                <h5 class="stat-subtitle">Goals</h5>
                <ul class="stat-list">
                  <li v-for="(goal, index) in match.stats.goles_1_names" 
                      :key="'goal1-' + index" 
                      class="stat-item">
                    <span class="goal-icon">⚽</span> {{ goal }}
                  </li>
                </ul>
              </div>
  
              <!-- Cards Container -->
              <div class="cards-container">
                <!-- Yellow Cards -->
                <div v-if="match.stats.amarillas_1_names?.length" class="card-section">
                  <h5 class="stat-subtitle">Yellow Cards</h5>
                  <ul class="stat-list">
                    <li v-for="(player, index) in match.stats.amarillas_1_names" 
                        :key="'yellow1-' + index" 
                        class="stat-item">
                      <span class="card yellow-card">🟨</span>
                      {{ player }}
                    </li>
                  </ul>
                </div>
  
                <!-- Red Cards -->
                <div v-if="match.stats.rojas_1_names?.length" class="card-section">
                  <h5 class="stat-subtitle">Red Cards</h5>
                  <ul class="stat-list">
                    <li v-for="(player, index) in match.stats.rojas_1_names" 
                        :key="'red1-' + index" 
                        class="stat-item">
                      <span class="card red-card">🟥</span>
                      {{ player }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
  
            <!-- Team 2 Stats -->
            <div class="team-stats">
              <h4 class="stats-title">{{ match.name_team_2 }} Statistics</h4>
              
              <!-- Goals -->
              <div v-if="match.stats.goles_2_names?.length" class="stat-section">
                <h5 class="stat-subtitle">Goals</h5>
                <ul class="stat-list">
                  <li v-for="(goal, index) in match.stats.goles_2_names" 
                      :key="'goal2-' + index" 
                      class="stat-item">
                    <span class="goal-icon">⚽</span> {{ goal }}
                  </li>
                </ul>
              </div>
  
              <!-- Cards Container -->
              <div class="cards-container">
                <!-- Yellow Cards -->
                <div v-if="match.stats.amarillas_2_names?.length" class="card-section">
                  <h5 class="stat-subtitle">Yellow Cards</h5>
                  <ul class="stat-list">
                    <li v-for="(player, index) in match.stats.amarillas_2_names" 
                        :key="'yellow2-' + index" 
                        class="stat-item">
                      <span class="card yellow-card">🟨</span>
                      {{ player }}
                    </li>
                  </ul>
                </div>
  
                <!-- Red Cards -->
                <div v-if="match.stats.rojas_2_names?.length" class="card-section">
                  <h5 class="stat-subtitle">Red Cards</h5>
                  <ul class="stat-list">
                    <li v-for="(player, index) in match.stats.rojas_2_names" 
                        :key="'red2-' + index" 
                        class="stat-item">
                      <span class="card red-card">🟥</span>
                      {{ player }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Tennis Result -->
          <div v-else class="tennis-result">
            Result: {{ formatTennisResult(match.res) }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useAuthStore } from "@/store/auth";
  
  export default {
    name: 'MatchDetails',
    props: {
      tournamentId: {
        type: String,
        required: true
      },
      matchId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        match: null
      };
    },
    created() {
      this.fetchMatchDetails();
    },
    methods: {
      async fetchMatchDetails() {
        try {
          const authStore = useAuthStore();
          const token = authStore.token;
          
          const response = await axios.get(
            `${process.env.VUE_APP_API_ENDPOINT}show_match/${this.tournamentId}/${this.matchId}`,
            {
              headers: {
                'Authorization': `Bearer ${authStore.decryptPassword(token)}`,
                'Content-Type': 'application/json'
              }
            }
          );
          
          this.match = response.data;
        } catch (error) {
          console.error('Error fetching match details:', error);
        }
      },
      formatTennisResult(resString) {
        try {
          const results = JSON.parse(resString);
          return Array.isArray(results) ? results.join(' - ') : '';
        } catch {
          return '';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .match-details {
    padding: 1rem;
  }
  
  .card {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    max-width: 48rem;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .card-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
  }
  
  .trophy {
    color: #fbbf24;
  }
  
  .card-content {
    padding: 1.5rem;
  }
  
  .loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
  }
  
  .text-muted {
    color: #6b7280;
  }
  
  .teams-container {
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .team-section {
    text-align: center;
  }
  
  .team-name {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .score {
    font-size: 1.875rem;
    font-weight: 700;
    color: #1a1a1a;
  }
  
  .vs-section {
    text-align: center;
  }
  
  .vs-text {
    font-size: 1.25rem;
    font-weight: 600;
    color: #6b7280;
  }
  
  .stats-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .team-stats {
    background-color: #f8fafc;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  
  .stats-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #2563eb;
    margin-bottom: 1rem;
  }
  
  .stat-section {
    margin-bottom: 1rem;
  }
  
  .stat-subtitle {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .stat-list {
    list-style: none;
    padding: 0;
  }
  
  .stat-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }
  
  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .card-section {
    margin-bottom: 1rem;
  }
  
  .yellow-card {
    color: #fbbf24;
  }
  
  .red-card {
    color: #dc2626;
  }
  
  .tennis-result {
    text-align: center;
    font-size: 1.25rem;
    padding: 1rem;
  }
  
  @media (max-width: 640px) {
    .teams-container {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
  
    .vs-section {
      margin: 0.5rem 0;
    }
  
    .cards-container {
      grid-template-columns: 1fr;
    }
  }
  </style>