import http from "../api";

async function login(username, password) {
    return await http.post('login', { 'email':username, 'password':password });
}

async function isAdminUser() {
    return await http.post('is_able');
}

export default {
    login,
    isAdminUser
};