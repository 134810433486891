// the axios instance and types
import http from "../api";

async function getTournaments() {
    return await http.get("tournament");
}

async function getAtournament(id) {
    return await http.get(`tournament/${id}`);
}

async function getBestScorerOfAtournament(id) {
    return await http.get(`getGoleadores/${id}`);
}

async function getMatchesOfAtournament(id) {
    return await http.get(`get_matches/${id}`);
}

async function geTeamsOfAtournament(id) {
    return await http.get(`tournament/${id}/teams`);
}

async function getYellowCardsByTournament(id) {
    return await http.get(`getMaximosAmonetados/${id}`);
}

async function getRedCardsByTournament(id) {
    return await http.get(`getMaximosExpulsados/${id}`);
}

export default {
    getTournaments,
    getAtournament,
    getBestScorerOfAtournament,
    getMatchesOfAtournament,
    geTeamsOfAtournament,
    getYellowCardsByTournament,
    getRedCardsByTournament
}