import http from "../api";

async function getDebtsTournament(idTournament) {
    return await http.get(`deubt_tournament/${idTournament}`);
}

async function getDebtsTeamTounament(idTeam, idTournament) {
    return await http.get(`deubt_of_team_in_tournament/${idTournament}/${idTeam}`);
}

async function addPayment(aPayment) {
    return await http.post("add_payment", aPayment);
}

async function getPayments() {
    return await http.get(`payments`);
}

export default {
    getDebtsTeamTounament,
    getDebtsTournament,
    addPayment,
    getPayments
};
