import {defineStore} from "pinia";
import {API} from "@/services";

export const useSuspensionStore = defineStore("suspensionStore", () => {
    async function dispatchGetSuspension(idTournament) {
        try {
            const {status, data} = await API.suspension.getSuspension(idTournament);

            if (status === 200) {
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    return {
        dispatchGetSuspension,
    }
})