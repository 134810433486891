<template>
  <v-container fluid class="tournament-matches">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <h1 class="text-h3 font-weight-bold gradient-text mb-8">Fase de grupos</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <div v-if="isLoading">
          <v-skeleton-loader type="article" />
        </div>

        <template v-else-if="Object.keys(matches).length">
          <div v-for="(matchesByDate, date) in matches" :key="date" class="date-group">
            <!-- Date Header -->
            <v-row class="round-separator">
              <v-col>
                <h2 class="text-h5 text-center round-title">
                  <v-icon icon="mdi-calendar" class="mr-2" />
                  Fecha n°{{ date.replace(/\D/g, "") }}
                </h2>
                <div class="separator-line"></div>
              </v-col>
            </v-row>

            <!-- Groups -->
            <div v-for="(matchesByGroup, group) in matchesByDate" :key="`${date}-${group}`" class="group-wrapper">
              <v-row class="group-header">
                <v-col>
                  <h3 class="text-h6">
                    <v-icon icon="mdi-account-group" class="mr-2" />
                    Grupo {{ group.replace(/\D/g, "") }}
                    <v-chip
                        class="ml-2"
                        color="primary"
                        size="small"
                        variant="flat"
                    >
                      {{ getGroupProgress(matchesByGroup) }}
                    </v-chip>
                  </h3>
                </v-col>
              </v-row>

              <match-row
                  v-for="match in matchesByGroup"
                  :key="match.id"
                  :match="match"
                  :is-able="isAble"
                  :tournament-details="tournamentDetails"
                  @match-updated="$emit('refresh-matches')"
              />
            </div>
          </div>
        </template>

        <v-card v-else class="empty-state">
          <v-card-text class="text-center pa-8">
            <v-icon icon="mdi-calendar-blank" size="64" color="grey" />
            <div class="text-h6 mt-4">No hay partidos programados</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
/* eslint-disable */
import { ref, computed } from 'vue';
import { useAuthStore } from "@/store/auth";
import { useTournamentDetailsStore } from "@/store/tournamentDetails";
import MatchRow from "./MatchRow.vue";

const props = defineProps({
  matches: {
    type: Object,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['refresh-matches']);
const authStore = useAuthStore();
const tournamentDetails = useTournamentDetailsStore();
const isAble = computed(() => authStore.isAble === 1);

const formatDate = (dateString) => {
  const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  return new Date(dateString).toLocaleDateString('es-ES', options);
};

const getGroupProgress = (matches) => {
  const total = matches.length;
  const completed = matches.filter(match => match.result).length;
  return `${completed}/${total} partidos completados`;
};
</script>

<style scoped>
.tournament-matches {
  padding: 2rem 0;
  background-color: #f8f9fa;
}

.gradient-text {
  background: linear-gradient(45deg, #2196F3, #00BCD4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.date-group {
  margin-bottom: 2rem;
}

.round-separator {
  margin: 2rem 0 1rem;
}

.round-title {
  color: #1976D2;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.separator-line {
  height: 2px;
  background: linear-gradient(90deg, transparent, #1976D2, transparent);
  margin-top: 0.5rem;
}

.group-wrapper {
  margin-bottom: 1.5rem;
}

.group-header {
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.empty-state {
  border-radius: 12px;
  background-color: white;
}

@media (max-width: 960px) {
  .gradient-text {
    font-size: 2rem;
  }

  .round-title {
    font-size: 1.2rem;
  }
}
</style>