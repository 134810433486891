<template>
  <v-container class="padding-left-sm">
    <v-row>
      <v-col class="padding-left-sm" offset-sm="0" offset-md="3" sm="12" md="6" lg="6">
        <v-card elevation="5">
          <v-card-item>
            <v-card-title>Completar resultado de equipos</v-card-title>
            <v-card-subtitle>Partido de <b>{{ local }}</b> vs <b>{{ visitante }}</b>, fecha Nro {{ fechaNro }}
            </v-card-subtitle>
          </v-card-item>
          <v-card-text v-if="isLocal && tournamentDetailStore.isFootball">
            <v-row>
              <v-col md="2" sm="6" class="align-start" align="right">Equipo a completar:</v-col>
              <v-col sm="2" align="left"><b>{{ local }}</b></v-col>
            </v-row>
            <v-row>
              <v-col md="2" sm="6" class="align-start col-padding-top" align="right">Goles de equipo:</v-col>
              <v-col sm="1" md="2" align-self="center">
                <VInlineSelect
                    v-model="formattedGoalsTeamA"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    name="nro"
                    empty-text="sin datos"
                    return-object
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="!isLocal && tournamentDetailStore.isFootball">
            <v-row>
              <v-col md="2" sm="6" class="align-start col-padding-top" align="right">Equipo a completar:</v-col>
              <v-col sm="2" align-self="start"><b>{{ visitante }}</b></v-col>
            </v-row>
            <v-row>
              <v-col md="2" sm="6" class="align-start" align="right" style="padding-top: 1.7%;">Goles de equipo:</v-col>
              <!--          <v-col ><v-text-field variant="underlined" hide-details :rules="[v => !!v || 'Field is required']" style="height: 10%; min-height: 20px;" dense ></v-text-field></v-col>-->
              <v-col sm="1">
                <VInlineSelect
                    v-model="formattedGoalsTeamB"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    empty-text="sin datos"
                    name="nro"
                    return-object
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table :items-per-page="40"
              :key="tableOptions.tableKey"
              density="default"
              :headers="headersFootball"
              :items="localTeam"
              :loading="tableOptions.loading"
              hide-footer
              v-if="localTeam !== '' && isLocal && tournamentDetailStore.isFootball"
          >

            <template v-slot:item="{ item, index}">
              <tr :class="{ 'even-row': index % 2 === 0 }">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.goal"
                      item-title="nro"
                      item-value="abbr"
                      empty-text="sin datos"
                      :items="optionsNumberGoals"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.yellow"
                      item-title="nro"
                      item-value="abbr"
                      empty-text="sin datos"
                      :items="optionsYellow"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.red"
                      item-title="isRed"
                      item-value="abbr"
                      :items="optionsRedCard"
                      empty-text="sin datos"
                      name="isRed"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
              </tr>
            </template>
            <template #bottom></template>
          </v-data-table>
          <v-data-table :items-per-page="40"
              :key="tableOptions.tableKey"
              density="default"
              :headers="headersFootball"
              :items="visitTeam"
              :loading="tableOptions.loading"
              hide-footer
              v-if="visitTeam !== '' && !isLocal && tournamentDetailStore.isFootball"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'even-row': index % 2 === 0 }">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.goal"
                      item-title="nro"
                      item-value="abbr"
                      :items="optionsNumberGoals"
                      empty-text="sin datos"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.yellow"
                      empty-text="sin datos"
                      item-title="nro"
                      item-value="abbr"
                      :items="optionsYellow"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.red"
                      empty-text="sin datos"
                      item-title="isRed"
                      item-value="abbr"
                      :items="optionsRedCard"
                      name="isRed"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
              </tr>
            </template>
            <template #bottom></template>
          </v-data-table>
          <v-data-table :items-per-page="40"
              :key="tableOptions.tableKey"
              density="comfortable"
              :headers="headersTennis"
              :items="localTeam"
              :loading="tableOptions.loading"
              hide-footer
              v-if="localTeam !== '' && isLocal && tournamentDetailStore.isTennis"
          >

            <template v-slot:item="{ item, index}">
              <tr :class="{ 'even-row': index % 2 === 0 }">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.goal"
                      item-title="nro"
                      item-value="abbr"
                      empty-text="sin datos"
                      :items="optionsNumberGoalsResult"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.yellow"
                      item-title="nro"
                      item-value="abbr"
                      empty-text="sin datos"
                      :items="optionsNumberGoalsResult"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.red"
                      item-title="nro"
                      item-value="abbr"
                      :items="optionsNumberGoalsResult"
                      empty-text="sin datos"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
              </tr>
            </template>
            <template #bottom></template>
          </v-data-table>

			<br>
			<br><hr>

          <v-card-actions v-if="!isLocal && isPlayoff">
            <v-row>
              <v-col md="4" class="align-start col-padding-top"><b>Resultado penales: </b></v-col>
              <v-col md="1" class="align-start col-padding-top">{{ local }}</v-col>
              <v-col md="1" align="center">
                <VInlineSelect
                    v-model="formattedPenaltiesTeamA"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    name="nro"
                    empty-text="sin datos"
                    return-object
                    table-field
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
              <v-col md="1">-</v-col>
              <v-col md="1">
                <VInlineSelect
                    v-model="formattedPenaltiesTeamB"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    name="nro"
                    return-object
                    empty-text="sin datos"
                    table-field
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
              <v-col md="1">{{ visitante }}</v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!isLocal && tournamentDetailStore.isFootball">
      <v-col cols="6" offset-md="3">
      <span>Elegir figura(opcional):</span>
      </v-col>
    </v-row>
    <v-row v-if="!isLocal && tournamentDetailStore.isFootball">
      <v-col md="3" offset-md="3" sm="12">
        <v-select
            label="Equipo de la figura"
            v-model="selectedTeam"
            :items="teamsOption"
            item-text="name"
            item-value="name"
            variant="outlined"
        ></v-select>
      </v-col>
      <v-col md="3" sm="12">
        <v-select
            v-model="selectedPlayer"
            :items="players"
            label="Figura del partido"
            item-value="id"
            :disabled="!selectedTeam"
            variant="outlined"

        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="!isLocal && tournamentDetailStore.isFootball">
      <v-col md="4" sm="12" offset-md="4">
      <v-text-field
          v-model="observations"
          label="Observaciones"
          type="text"
          color="primary"
          outlined
          dense
          class="mb-4"
      />
      </v-col>
    </v-row>
    <v-row>
      <v-col offset-sm="0" offset-md="3" md="6" class="horizontal-gradient ">
        <v-col>
          <v-btn variant="flat" elevation="2" density="comfortable" @click="getModifiedItems" v-if="isLocal && tournamentDetailStore.isFootball">continuar
          </v-btn>
          <v-btn variant="outlined" class="background-btn" density="comfortable" @click="saveMatchReport"
                 v-if="!isLocal || tournamentDetailStore.isTennis">finalizar reporte partido
          </v-btn>
        </v-col>
        <v-col v-if="!isLocal && tournamentDetailStore.isFootball">
          <v-btn variant="outlined" class="background-btn" density="comfortable" @click="goBack" v-if="!isLocal">atras
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
  <ErrorAlert :type="errorType"/>
  <!-- todo validar que si es tenis el equipo no deberia tener jugadores -->
</template>

<script setup>
/* eslint-disable */
import {reactive, ref, onMounted, computed, watch} from 'vue';
import {useMatchtore} from "@/store/match";
import {useRoute, useRouter} from "vue-router";
import ErrorAlert from "@/components/messageUser/ErrorAlert.vue";
import {useAlertStore} from "@/models/useErrorHandling";
import {id} from "vuetify/locale";
import {useTournamentDetailsStore} from "@/store/tournamentDetails";

const errorStore = useAlertStore();
const isLocal = ref(true)
const matchStore = useMatchtore();
const tournamentDetailStore = useTournamentDetailsStore();
const route = useRoute();
const router = useRouter();
const matchId = route.params.matchId;
const tournamentId = route.params.tournamentId;
const local = ref(null);
const visitante = ref(null);
const fechaNro = ref(null);
const localTeam = ref('');
const visitTeam = ref(null);
const tournament = ref(null);
const idTeamA = ref(null);
const idTeamB = ref(null);
const errorType = ref('Error carga resultados');
const observations = ref('');

const headersFootball = ref([
  {
    align: 'center',
    key: 'name',
    title: 'Nombre',
    width: 325,
  },
  {
    align: 'start',
    key: 'goal',
    title: 'Goles',
  },
  {
    align: 'start',
    key: 'yellow',
    title: 'Amarillas',
  },
  {
    align: 'center',
    key: 'red',
    title: 'Roja',
  },
]);

const headersTennis = ref([
      {
        align: 'center',
        key: 'name',
        title: 'Nombre',
        width: 325,
      },
      {
        align: 'start',
        key: 'setOne',
        title: 'SET 1',
        width: 600,
        sortable: false
      },
      {
        align: 'start',
        key: 'setTwo',
        title: 'SET 2',
        width: 600,
        sortable: false
      },
      {
        align: 'start',
        key: 'setThree',
        title: 'SET 3',
        width: 600,
        sortable: false
      },
    ]);


const numFase = ref("");
const modifiedItems = ref([]);
const localTeamModified = ref([]);
const playersGoalsTeamA = ref([]);
const yellowsTeamA = ref([]);
const redTeamA = ref([]);
const redTeamB = ref([]);
const playersGoalsTeamB = ref([]);
const yellowsTeamB = ref([]);
const goalsTeamA = ref('');
const goalsTeamB = ref('');
const penaltiesTeamA = ref('');
const penaltiesTeamB = ref('');
const selectedTeam = ref(null);
const selectedPlayer = ref(null);
const teamsOption= ref([]);
const players = ref([]);

const loadPlayers = () => {
  if(selectedTeam.value === '-') {
    selectedTeam.value = null;
    return;
  }
  const team = teamsOption.value.find((t) => t.title === selectedTeam.value);
  players.value = team ? team.players.map(player => ({ id:player.id, title: player.name })) : [];
  console.log(players.value);
  selectedPlayer.value = null; // Reset player selection on team change
}

watch(selectedTeam, loadPlayers);

const optionsRedCard = reactive([
  {
    abbr: false,
    isRed: 'No',
  },
  {
    abbr: true,
    isRed: 'Si',
  },
  {
    abbr: 'Sin datos',
    isRed: null,

  }
]);
const optionsYellow = reactive([
  {
    abbr: '1',
    nro: 1,
  },
  {
    abbr: '2',
    nro: 2,
  },
  {
    abbr: 'sin datos',
    nro: 'sin datos',
  }
]);
const isPlayoff = ref(false);

const formattedGoalsTeamA = computed({
  get() {
    return goalsTeamA.value.hasOwnProperty('abbr') ? goalsTeamA.value.abbr : goalsTeamA.value;
  },
  set(newValue) {
    goalsTeamA.value = newValue;
  }
});
const formattedGoalsTeamB = computed({
  get() {
    return goalsTeamB.value.hasOwnProperty('abbr') ? goalsTeamB.value.abbr : goalsTeamB.value;
  },
  set(newValue) {
    goalsTeamB.value = newValue;
  }
});

const formattedPenaltiesTeamA = computed({
  get() {
    return penaltiesTeamA.value.hasOwnProperty('abbr') ? penaltiesTeamA.value.abbr : penaltiesTeamA.value;
  },
  set(newValue) {
    penaltiesTeamA.value = newValue;
  }
});
const formattedPenaltiesTeamB = computed({
  get() {
    return penaltiesTeamB.value.hasOwnProperty('abbr') ? penaltiesTeamB.value.abbr : penaltiesTeamB.value;
  },
  set(newValue) {
    penaltiesTeamB.value = newValue;
  }
});
const optionsNumberGoalsResult = reactive([
  {abbr: 'sin datos', nro: 'sin datos'},
  ...Array.from({length: 101}, (_, i) => ({abbr: i.toString(), nro: i}))
]);
const optionsNumberGoals = reactive([
  {abbr: 'sin datos', nro: 'sin datos'},
  ...Array.from({length: 100}, (_, i) => ({abbr: (i + 1).toString(), nro: i + 1}))
]);

onMounted(async () => {
  const {success, status, content} = await matchStore.dispatchGetInfoMatch(matchId, tournamentId);
  if (!success) {
    console.log("Ups, something happened 🙂");
    console.log("Api status ->", status);
    return;
  }
  local.value = content.name_1;
  localTeam.value = content.players1;
  console.log(localTeam.value);
  visitante.value = content.name_2;
  visitTeam.value = content.players2;
  teamsOption.value.push({title: '-', players: []});
  teamsOption.value.push({title: local.value, players: content.players1});
  teamsOption.value.push({title: visitante.value, players: content.players2});
  console.log(teamsOption);
  fechaNro.value = content.number_of_date;
  tournament.value = content.id_tournament;
  idTeamA.value = content.id_team_1;
  idTeamB.value = content.id_team_2;
  numFase.value = content.current_phase;
  isPlayoff.value = content.current_schema === "playoff";
});
const tableOptions = reactive({
  itemsPerPage: 100,
  loading: false,
  sortBy: [{key: 'name', order: 'asc'}],
  tableKey: new Date().getUTCMilliseconds(),
});

const getModifiedItems = () => {
  localTeamModified.value = localTeam.value.filter((item) => {
    return item.yellow.nro !== '' || item.goal.nro !== '';
  });

  playersGoalsTeamA.value = localTeam.value.reduce((acc, item) => {
    const goalCount = parseInt(item.goal.nro, 10) || 0;
    return [...acc, ...Array(goalCount).fill(item.id)];
  }, []);

  yellowsTeamA.value = localTeam.value.reduce((acc, item) => {
    const yellowCount = parseInt(item.yellow.nro, 10) || 0;
    return [...acc, ...Array(yellowCount).fill(item.id)];
  }, []);

  redTeamA.value = localTeam.value.reduce((acc, item) => {
    const redCount = (item.red ? 1 : 0) || 0;
    return [...acc, ...Array(redCount).fill(item.id)];
  }, []);
  let isOK = validateTeamInfo(goalsTeamA, playersGoalsTeamA);
  if (!isOK) {
    return
  }
  isLocal.value = false;
};

const goBack = () => {
  isLocal.value = true;
}

const saveFootballResult = () => {
  playersGoalsTeamB.value = visitTeam.value.reduce((acc, item) => {
    const goalCount = parseInt(item.goal.nro, 10) || 0;
    return [...acc, ...Array(goalCount).fill(item.id)];
  }, []);

  yellowsTeamB.value = visitTeam.value.reduce((acc, item) => {
    const yellowCount = parseInt(item.yellow.nro, 10) || 0;
    return [...acc, ...Array(yellowCount).fill(item.id)];
  }, []);

  redTeamB.value = visitTeam.value.reduce((acc, item) => {
    const redCount = (item.red ? 1 : 0) || 0;
    return [...acc, ...Array(redCount).fill(item.id)];
  }, []);
  const dataForm = fillFootballData();
  let isOK = validateTeamInfo(goalsTeamB, playersGoalsTeamB);
  if (!isOK) {
    return null;
  } else {
    return dataForm;
  }
}

async function saveMatchReport () {
  let dataForm = null;
  if(tournamentDetailStore.isFootball){
    dataForm = saveFootballResult();
  }

  if(tournamentDetailStore.isTennis){
    dataForm = saveTennisResult();
  }
  if(dataForm === null){ return;}
  const response = await matchStore.dispatchAddMatch(dataForm);

  if (response.success) {
    router.push({
      name: 'TournamentDetails',
      params: {
        id: tournamentId
      },
    })
  }
}

const saveTennisResult = () => {
  playersGoalsTeamA.value = localTeam.value.reduce((acc, item) => {
    const setOne = parseInt(item.goal.nro, 10) || 0;
    return [...acc, ...Array(setOne).fill(item.id)];
  }, []);

  yellowsTeamA.value = localTeam.value.reduce((acc, item) => {
    const setTwo = parseInt(item.yellow.nro, 10) || 0;
    return [...acc, ...Array(setTwo).fill(item.id)];
  }, []);

  redTeamA.value = localTeam.value.reduce((acc, item) => {
    const setThree = parseInt(item.red.nro, 10) || 0;
    return [...acc, ...Array(setThree).fill(item.id)];
  }, []);

  let playerOne = localTeam.value[0];
  let playerTwo = localTeam.value[1];
  let matchesOne = 0;
  let matchesTwo = 0;
  matchesOne = Number(playerOne.goal.nro > playerTwo.goal.nro) + Number(playerOne.yellow.nro > playerTwo.yellow.nro)
  + Number(playerOne.red && playerTwo.red ? playerOne.red.nro > playerTwo.red.nro : 0);
  matchesTwo = Number(playerOne.goal.nro < playerTwo.goal.nro) + Number(playerOne.yellow.nro < playerTwo.yellow.nro)
  + Number(playerOne.red && playerTwo.red ? playerOne.red.nro < playerTwo.red.nro : 0);

  if(!validateTennisInfo(playerOne, playerTwo, matchesOne, matchesTwo)){
    return null;
  }


  let winner = matchesOne > matchesTwo ? playerOne.id : playerTwo.id;
  let res = [matchesOne, matchesTwo];
  let resSets = [`${playerOne.goal.nro}/${playerTwo.goal.nro}`, `${playerOne.yellow.nro}/${playerTwo.yellow.nro}`,
    (playerOne.red && playerTwo.red ? `${playerOne.red.nro}/${playerTwo.red.nro}`: '')];

  const form = {
    "id_1" : playerOne.id ,
      "id_2" : playerTwo.id ,
      "idTournament" : tournament.value,
      "numFase" : numFase.value ,
      "idWinner" : winner , // id del eqipo o 0 en caso de empate
      "stats" : {
        "res_to_show" : resSets ,
        "res" : res
      }
  }
  return {"matches":[form]};
}

const fillFootballData = () => {
  let winner = goalsTeamA.value.nro > goalsTeamB.value.nro ? idTeamA.value : idTeamB.value; // y si es empate ???
  winner = goalsTeamA.value.nro === goalsTeamB.value.nro ? 0 : winner;
  let penaltiesResult = '';
  if (winner === 0) {
    penaltiesResult = penaltiesTeamA.value.nro + '-' + penaltiesTeamB.value.nro;
  }
  if (isPlayoff.value && winner === 0){
	// osea es un empate
	if (penaltiesTeamA.value.nro > penaltiesTeamB.value.nro){
		winner =idTeamA.value;
	}
	else{
		winner =idTeamB.value;
	}
  }
  const dataForm = {
    "matches": [
      {
        "id_1": idTeamA.value,
        "id_2": idTeamB.value,
        "idTournament": tournament.value,
        "numFase": numFase.value, //indicar si es liga o playoff 1 o 2 respectivamente
        "idWinner": winner,
        "observations": observations.value,
        "stats": {
          "goles_1": playersGoalsTeamA.value,
          "goles_2": playersGoalsTeamB.value,
          "res": [parseInt(goalsTeamA.value.nro), parseInt(goalsTeamB.value.nro)],
          "amarillas_1": yellowsTeamA.value,
          "amarillas_2": yellowsTeamB.value,
          "rojas_1": redTeamA.value,
          "rojas_2": redTeamB.value,
          "cantidad_fechas_suspension_rojas_1": [], // si no vienen definidas le mandamos 1 fecha de suspension
          "cantidad_fechas_suspension_rojas_2": [],
          "penales": penaltiesResult,
          "mvp": selectedPlayer.value,
        }
      }
    ]
  };
  return dataForm;
}

const validateTeamInfo = (goalsTeam, playersTeam) => {
  let message = '';
  if (goalsTeam.value === '') {
    message += "No se registraron goles. ";
  }
  if (playersTeam.value.length < goalsTeam.value) {
    message += "Los goles generales no coinciden con los goles de los jugadores. ";
  }

  // esto no funciono
  if (!isLocal.value && goalsTeamA.value === goalsTeamB && penaltiesTeamA.value !== penaltiesTeamB.value && numFase === 2) {
    message += "El resultado fue un empate, debe completar los penales";
  }

  // es playoff y puso empate , entonces si o si tiene q completar los penales , y
  // q alguno de los dos haya ganado
  if (isPlayoff.value && goalsTeamA.value.nro === goalsTeamB.value.nro && (penaltiesTeamA.value === penaltiesTeamB.value || (penaltiesTeamA.value == null || penaltiesTeamA.value == null)   )) {
    message += "El resultado fue un empate y estamos en playoff , por favor complete los penales o designe un ganador";
  }
  if (message !== '') {
    errorStore.handleError(message)
    return false;
  }
  return true;
}

const validateTennisInfo = (playerOne, playerTwo, winsOne, winsTwo) => {

  let message = '';

  if(playerOne.goal.nro === undefined || playerOne.goal.nro === 'sin datos' || playerTwo.goal.nro === 'sin datos' || playerTwo.goal.nro === undefined ){
    message += "El set nro 1 faltan datos. "
  }
  if( playerOne.goal.nro === playerTwo.goal.nro) {
    message += "El set nro 1 tiene que haber un ganador. "
  }
  if(playerOne.yellow.nro === undefined || playerOne.yellow.nro === 'sin datos' || playerTwo.yellow.nro === 'sin datos' || playerTwo.yellow.nro === undefined ){
    message += "El set nro 2 faltan datos. "
  }
  if( playerOne.yellow.nro === playerTwo.yellow.nro) {
    message += "El set nro 2 tiene que haber un ganador. "
  }
  if(winsOne === winsTwo){
    message += "Faltan completar sets. "
  }
  if (message !== '') {
    errorStore.handleError(message)
    return false;
  }
  return true;
}
</script>
<style>
.horizontal-gradient {
  background: linear-gradient(to left, blue, skyblue);
}

.even-row {
  background-color: #f9f3f3; /* Color de fondo para filas pares */
}

.background-btn {
  background-color: whitesmoke !important;
}

.background-btn i:hover {
  background-color: #3C618B !important;
}

.background-btn::before {
  background-color: #3C618B !important;
}

.col-padding-top {
  padding-top: 1.7% !important;
}

@media (max-width: 600px) {
  /* Ajusta el ancho máximo según tu diseño */
  .padding-left-sm {
    /* Estilos adicionales para pantallas sm y más pequeñas */
    padding-left: 0.1% !important; /* Otra forma de ocultar el elemento */
  }

  .col-padding-top {
    padding-top: 0px !important;
  }
}

:deep([class*="v-input__append"]) {
  display: none !important;
}
</style>