<script setup>
import {computed, onMounted, ref, watchEffect} from 'vue'
import {useField, useForm} from 'vee-validate'
import {object, number} from 'yup'
import {useTeamStore} from "@/store/team"
import {useTournamentStore} from "@/store/tournament"
import {useAccountingStore} from "@/store/accountingRecord"
import {useRouter} from "vue-router"

// Form state
const router = useRouter()
const isSubmitting = ref(false)
const showError = ref(false)
const errorMessage = ref('')
const paymentMethod = ref('efectivo')
const fullPayment = ref(false);
const disabled = ref(false);

// Form validation schema
const schema = object({
  amount: number()
      .required('Amount is required')
      .positive('Amount must be greater than zero')
      .typeError('Amount must be numeric'),
  team_id: number()
      .required('Team selection is required')
      .typeError('Please select a team'),
  tournament_id: number()
      .required('Tournament selection is required')
      .typeError('Please select a tournament')
})

const {handleSubmit} = useForm({
  validationSchema: schema
})

// Form fields
const {value: amount, errorMessage: amountError} = useField('amount')
const {value: team_id, errorMessage: teamError} = useField('team_id')
const {value: tournament_id, errorMessage: tournamentError} = useField('tournament_id')
const reasons = ref([
  {id: 0, name: 'SUSCRIPCION', label: 'Suscripción'},
  {id: 1, name: 'CANCHA', label: 'Cancha'},
  {id: 2, name: 'OTROS', label: 'Otros'}
])
const reason = ref("")
const observations = ref("")
const id_match = ref(null)

// Data lists
const teams = ref([])
const tournaments = ref([])
const teamsByTournament = ref([])

// Computed properties
const isFormValid = computed(() => {
  return !amountError.value && !teamError.value && !tournamentError.value &&
      amount.value && team_id.value && tournament_id.value
})

watchEffect(async () => {
  const tournamentStore = useTournamentStore()
  if (tournament_id.value === null) {
    teamsByTournament.value = teams.value
  } else {
    const response = await tournamentStore.getTeamsOfATournament(tournament_id.value)
    teamsByTournament.value = response.content
  }
})

onMounted(async () => {
  try {
    const teamStore = useTeamStore()
    const tournamentStore = useTournamentStore()

    const [teamResponse, tournamentResponse] = await Promise.all([
      teamStore.dispatchGetTeams(),
      tournamentStore.dispatchGetTournaments()
    ])

    if (!teamResponse.success) throw new Error("Failed to load teams")
    if (!tournamentResponse.success) throw new Error("Failed to load tournaments")

    teams.value = teamStore.teams
    teamsByTournament.value = teamStore.teams
    tournaments.value = tournamentResponse.content
  } catch (error) {
    console.error("Error:", error)
    showError.value = true
    errorMessage.value = error.message
  }
})

const submit = handleSubmit(async (values) => {
  try {
    isSubmitting.value = true
    showError.value = false

    const paymentData = {
      amount: Number(values.amount),
      team_id: values.team_id,
      tournament_id: values.tournament_id,
      motivo: reason.value,
      observations: observations.value,
      id_match: id_match.value,
      type: paymentMethod.value === 'efectivo' ? 'Efectivo' : 'Transferencia',
    }

    const accountingStore = useAccountingStore()
    const response = await accountingStore.dispatchAddPayment(paymentData)

    if (response.success) {
      router.push({name: 'accountingRecord'})
    } else {
      throw new Error("Error al procesar cobro")
    }
  } catch (error) {
    console.error('Error:', error)
    showError.value = true
    errorMessage.value = error.message
  } finally {
    isSubmitting.value = false
  }
})
</script>

<template>
  <div class="payment-form-container">
    <div class="payment-card">
      <div class="card-header">
        <h2 class="card-title">Agregar Cobro</h2>
      </div>

      <div class="card-content">
        <div v-if="showError" class="error-alert">
          {{ errorMessage }}
        </div>

        <form @submit.prevent="submit" class="form-content">
          <!-- Payment Type Selection -->
          <div class="form-group" v-if="disabled">
            <label>Tipo De Pago</label>
            <div class="toggle-container">
              <span>{{ fullPayment ? 'Pago Total' : 'Pago Parcial' }}</span>
              <label class="switch">
                <input type="checkbox" v-model="fullPayment">
                <span class="slider"></span>
              </label>
            </div>
          </div>

          <!-- Amount Input -->
          <div class="form-group">
            <label for="amount">Monto</label>
            <div class="input-with-prefix">
              <span class="prefix">$</span>
              <input
                  id="amount"
                  type="number"
                  v-model="amount"
                  class="form-input"
                  :class="{ 'error': amountError }"
                  step="0.01"
                  min="0"
                  placeholder="0.00"
              >
            </div>
            <span v-if="amountError" class="error-message">{{ amountError }}</span>
          </div>

          <!-- Payment Method -->
          <div class="form-group">
            <label>Método De Pago</label>
            <div class="toggle-container">
              <span>{{ paymentMethod === 'efectivo' ? 'Efectivo' : 'Transferencia' }}</span>
              <label class="switch">
                <input
                    type="checkbox"
                    :checked="paymentMethod === 'transferencia'"
                    @change="paymentMethod = $event.target.checked ? 'transferencia' : 'efectivo'"
                >
                <span class="slider"></span>
              </label>
            </div>
          </div>

          <!-- Reason Select -->
          <div class="form-group">
            <label for="reason">Motivo</label>
            <select
                id="reason"
                v-model="reason"
                class="form-select"
            >
              <option value="">Seleccionar un motivo</option>
              <option
                  v-for="option in reasons"
                  :key="option.id"
                  :value="option.name"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <!-- Tournament Select -->
          <div class="form-group">
            <label for="tournament">Torneo</label>
            <select
                id="tournament"
                v-model="tournament_id"
                class="form-select"
                :class="{ 'error': tournamentError }"
            >
              <option value="">Selecciona un torneo</option>
              <option
                  v-for="tournament in tournaments"
                  :key="tournament.id"
                  :value="tournament.id"
              >
                {{ tournament.name_of_tournament }}
              </option>
            </select>
            <span v-if="tournamentError" class="error-message">{{ tournamentError }}</span>
          </div>

          <!-- Team Select -->
          <div class="form-group">
            <label for="team">Equipo</label>
            <select
                id="team"
                v-model="team_id"
                class="form-select"
                :class="{ 'error': teamError }"
            >
              <option value="">Seleccionar un equipo</option>
              <option
                  v-for="team in teamsByTournament"
                  :key="team.id"
                  :value="team.id"
              >
                {{ team.name }}
              </option>
            </select>
            <span v-if="teamError" class="error-message">{{ teamError }}</span>
          </div>

          <!-- Observations -->
          <div class="form-group">
            <label for="observations">Observaciones</label>
            <input
                id="observations"
                type="text"
                v-model="observations"
                class="form-input"
                placeholder="Agregar alguna otra observacion..."
            >
          </div>

          <!-- Submit Button -->
          <button
              type="submit"
              class="submit-button"
              :class="{ 'loading': isSubmitting }"
              :disabled="isSubmitting || !isFormValid"
          >
            <span v-if="isSubmitting" class="loader"></span>
            {{ isSubmitting ? 'Procesando...' : 'Agregar cobro' }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.payment-form-container {
  max-width: 32rem;
  margin: 1rem auto;
  padding: 1rem;
}

.payment-card {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #1f2937;
}

.card-content {
  padding: 1.5rem;
}

.error-alert {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #374151;
}

.input-with-prefix {
  position: relative;
  display: flex;
  align-items: center;
}

.prefix {
  position: absolute;
  left: 0.75rem;
  color: #6b7280;
}

.form-input,
.form-select {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: border-color 0.15s ease;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #2563eb;
  ring: 2px solid rgba(37, 99, 235, 0.1);
}

.input-with-prefix .form-input {
  padding-left: 1.75rem;
}

.error {
  border-color: #dc2626;
}

.error-message {
  font-size: 0.875rem;
  color: #dc2626;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f9fafb;
  border-radius: 0.375rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1d5db;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2563eb;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.submit-button:hover:not(:disabled) {
  background-color: #1d4ed8;
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submit-button.loading {
  position: relative;
  color: transparent;
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  position: absolute;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>