import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify'; // Importa la configuración de Vuetify
import { createVInlineFields } from '@wdns/vuetify-inline-fields';
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css';

const pinia = createPinia();
pinia.use(createPersistedState({
    key: id => `__persisted__${id}`,
}))

const VInlineFields = createVInlineFields({});


const app = createApp(App);
app.use(router);
app.use(vuetify); // Usa Vuetify como plugin
app.use(VInlineFields)
app.use(pinia)
app.mount('#app');
