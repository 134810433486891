import axios from 'axios';
import { useAuthStore } from "@/store/auth";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
});

instance.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2))
    const token = useAuthStore().token; // Obtener el token del store
    if (token) {
        request.headers.Authorization = `Bearer ${useAuthStore().decryptPassword(token)}`;
    }
    return request;
});

instance.interceptors.response.use(response => {
    console.log('Starting response', JSON.stringify(response, 2))
    return response;
})

export default instance;