<template>
  <v-container fluid class="tournament-playoffs">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <h1 class="text-h3 font-weight-bold gradient-text mb-8">Playoffs</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <div v-for="(match, index) in matches" :key="index" class="match-wrapper">
          <!-- Round Separator -->
          <v-row v-if="shouldShowSeparator(index)" class="round-separator">
            <v-col>
              <h2 class="text-h5 text-center round-title">{{ getRoundName(index) }}</h2>
              <div class="separator-line"></div>
            </v-col>
          </v-row>

          <!-- Match Card -->
          <v-card class="match-card">
            <div class="match-container">
              <!-- Main Match Content -->
              <div
                  :class="['match-content', {'match-content--clickable': match.result && is_able}]"
                  @click="match.result && is_able ? gotoMatch(match.id, match.id_tournament) : null"
              >
                <!-- Team 1 -->
                <div class="team-container team-container--right">
                  <div v-if="tournamentDetails.isTennis && match.id_team_1 === match.winner" class="winner-container">
                    <v-btn rounded="xl" color="success" variant="flat" class="winner-btn">
                      <span class="winner-text">Ganador<br>{{ match.name_team_1 }}</span>
                    </v-btn>
                  </div>
                  <span v-else class="team-name">{{ match.name_team_1 }}</span>
                </div>

                <!-- Score/VS -->
                <div class="score-container">
                  <span v-if="match.result && tournamentDetails.isFootball" class="score-display">
                    {{ match.res_to_show !== '' && match.res_to_show !== null
                      ? formatTennisScore(match.res_to_show)
                      : match.result.replace(/\s/g, '') }}
                  </span>
                  <span v-else class="vs-text">vs</span>
                </div>

                <!-- Team 2 -->
                <div class="team-container team-container--left">
                  <div v-if="tournamentDetails.isTennis && match.id_team_2 === match.winner" class="winner-container">
                    <v-btn rounded="xl" color="success" variant="flat" class="winner-btn">
                      <span class="winner-text">Ganador<br>{{ match.name_team_2 }}</span>
                    </v-btn>
                  </div>
                  <span v-else class="team-name">{{ match.name_team_2 }}</span>
                </div>
              </div>

              <!-- Match Actions - Now on the right side -->
              <div v-if="showActions(match)" class="match-actions">
                <template v-if="!match.result && is_able">
                  <v-btn
                      color="primary"
                      variant="outlined"
                      size="small"
                      class="action-btn"
                      @click.stop="getPlanilla(match.id, match.id_tournament)"
                  >
                    Abrir planilla
                  </v-btn>
                  <v-btn
                      color="primary"
                      variant="tonal"
                      size="small"
                      class="action-btn"
                      @click.stop
                      :to="{ name: 'matchResult', params: { matchId: match.id, tournamentId: match.id_tournament }}"
                  >
                    Cargar partido
                  </v-btn>
                </template>
                <template v-if="match.result && is_able">
                  <v-btn
                      color="error"
                      variant="outlined"
                      size="small"
                      class="action-btn"
                      @click.stop="openConfirmation(match.id, match.id_tournament)"
                  >
                    Borrar resultado
                  </v-btn>
                </template>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.tournament-playoffs {
  padding: 2rem 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.gradient-text {
  background: linear-gradient(45deg, #2196F3, #00BCD4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.round-separator {
  margin: 2rem 0;
}

.round-title {
  color: #1976D2;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.separator-line {
  height: 2px;
  background: linear-gradient(90deg, transparent, #1976D2, transparent);
  margin-top: 0.5rem;
}

.match-wrapper {
  margin-bottom: 1.5rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.match-card {
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
}

.match-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.match-content {
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 60px;
  padding: 0.5rem;
  transition: all 0.3s ease;
  margin-right: 1rem;
}

.match-content--clickable {
  cursor: pointer;
}

.match-content--clickable:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.team-container {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 60px;
}

.team-container--right {
  justify-content: flex-end;
  padding-right: 1.5rem;
}

.team-container--left {
  justify-content: flex-start;
  padding-left: 1.5rem;
}

.winner-container {
  display: flex;
  align-items: center;
}

.score-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  min-width: 120px;
}

.team-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #37474F;
}

.score-display {
  font-weight: 700;
  color: black;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #20D19A;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  display: inline-block;
}

.vs-text {
  color: #78909C;
  font-weight: 500;
  font-size: 1rem;
}

.winner-btn {
  text-transform: none !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
}

.winner-text {
  font-size: 0.9rem;
  line-height: 1.2;
  text-align: center;
}

.match-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 140px;
  justify-content: center;
  align-items: stretch;
}

.action-btn {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
}

@media (max-width: 960px) {
  .match-container {
    flex-direction: column;
    padding: 0.5rem;
  }

  .match-content {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .match-actions {
    flex-direction: row;
    width: 100%;
    padding: 0.5rem;
    border-top: 1px solid #E0E0E0;
  }

  .team-container {
    min-height: 50px;
  }

  .team-name {
    font-size: 0.9rem;
  }

  .score-container {
    width: 80px;
    min-width: 80px;
  }

  .score-display {
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
  }

  .action-btn {
    flex: 1;
    font-size: 0.8rem;
  }

  .team-container--right {
    padding-right: 0.8rem;
  }

  .team-container--left {
    padding-left: 0.8rem;
  }
}
</style>
<script>
import {useMatchtore} from "@/store/match";
import {useAuthStore} from "@/store/auth";
import {useAlertStore} from "@/models/useErrorHandling";
import {useTournamentDetailsStore} from "@/store/tournamentDetails";

export default {
  data() {
    return {
      isHovered: null,
      is_able: useAuthStore().isAble,
      tournamentDetails: useTournamentDetailsStore(),
    };
  },
  mounted() {
    console.log("aca grilla ");
    console.log(this.matches);
  },
  props: {
    matches: Array,
  },
  computed: {
    rounds() {
      const totalMatches = this.matches.length;
      const rounds = [];
      let matchesInRound = totalMatches;
      // Itera y divide por 2 en cada ronda hasta llegar a 1 partido (la final)
      while (matchesInRound > 1) {
        matchesInRound = Math.ceil(matchesInRound / 2);  // Redondeo hacia arriba para asegurar enteros
        rounds.push(matchesInRound);
      }
      // Añadir la final como la última ronda
      rounds.push(1);
      console.log("rondas");
      console.log(rounds);
      return rounds;
    },
    roundNames() {
      if (this.matches.length == 7) {
        return ["Cuartos", "Semis", "Final"];
      }
      if (this.matches.length == 15) {
        return ["Octavos", "cuartos", "semis", "final"];
      }
      if (this.matches.length == 31) {
        return ["primera ronda", "octavos", "cuartos", "semis", "final"];
      }
      if (this.matches.length == 63) {
        return ["primera ronda", "segunda ronda", "octavos", "cuartos", "semis", "final"];
      }
      if (this.matches.length == 3) {
        return ["semis", "final"];
      }
      return ["final"];
    },
    formattedResult() {
      return this.match.res_to_show !== '' && this.match.res_to_show !== null
          ? this.formatTennisScore(this.match.res_to_show)
          : this.match.result.replace(/\s/g, '');
    },
  },
  methods: {
    shouldShowSeparator(index) {
      let cumulativeMatches = 0;

      if (index === 0) // Para el primer partido de la primera ronda
        return true;

      for (let i = 0; i < this.rounds.length; i++) {
        cumulativeMatches += this.rounds[i];
        if (index === cumulativeMatches) {
          return true;
        }
      }

    },
    getRoundName(index) {
      let cumulativeMatches = 0;

      for (let i = 0; i < this.rounds.length; i++) {
        cumulativeMatches += this.rounds[i];
        if (index < cumulativeMatches) {
          return this.roundNames[i];
        }
      }
      console.log("partidos");
      console.log(this.matches);
      return '';
    }
    ,
    gotoMatch(idMatchFixture, id_tournament) {
      this.$router.push({
        name: 'showMatch',
        params: {"tournamentId": id_tournament, "matchId": idMatchFixture}
      });
    },
    async getPlanilla(idMatchFixture, id_tournament) {
      try {
        // Hacemos la solicitud POST usando async/await
        const matchStore = useMatchtore();
        //const response =  API.matchs.getPdfMatch(idMatchFixture, id_tournament);
        const response = matchStore.dispatchPdfMatch(idMatchFixture, id_tournament);

        // Creamos un URL a partir del archivo binario recibido
        const url = window.URL.createObjectURL(new Blob([(await response).content]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'match_planilla.pdf'); // Nombre del archivo a descargar
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Removemos el enlace después de la descarga

      } catch (error) {
        // Manejo de errores
        console.error("There was an error generating the PDF:", error);
      }
    },
    formatTennisScore(results) {
      results = JSON.parse(results);
      // Verifica si results es un array y tiene al menos un resultado
      if (Array.isArray(results) && results.length > 0) {
        return results.join('-'); // Une los resultados con ' - '
      }
      return ''; // Devuelve una cadena vacía si no hay resultados
    },
    openConfirmation(aMatchId, tournamentId) {
      const alertStore = useAlertStore();
      alertStore.setMatchConfirmation(aMatchId, tournamentId);
      let message = "Eliminar resultado"
      let content = "Esta seguro que desea eliminar el resultado?"
      let confirmButtonText = "si, estoy seguro"
      console.log("llegue al componente principal");
      alertStore.showConfirmationAlert(
          message,
          content,
          confirmButtonText,
      );
    },
    showActions(match) {
      return (match.name_team_1 != 'BYE' && match.name_team_2 != 'BYE') &&
          (match.name_team_1 != '' && match.name_team_2 != '') &&
          (match.name_team_1 != 'Proximamente' && match.name_team_2 != 'Proximamente') &&
          this.is_able;
    },
  }
};
</script>