// services/index.js
import teamController from "./team";
import matchController from "./match";
import tournamentController from "./tournament";
import authController from "./auth";
import accountingRecordController from "@/services/accountingRecord";
import suspensionController from "@/services/suspension";

export const API = {
    teams: teamController,
    matchs: matchController,
    tournaments: tournamentController,
    auth: authController,
    accountingRecord: accountingRecordController,
    suspension: suspensionController,
};
