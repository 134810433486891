<script setup>
/* eslint-disable */
import { useAlertStore} from "@/models/useErrorHandling";
import {storeToRefs} from "pinia";
import {useMatchtore} from "@/store/match";
import {useRouter} from "vue-router";
import {ref} from "vue";

const confirmationPopupStore = useAlertStore();
const { showPopup, message, content, confirmButtonText, tournamentIdConfirmation, matchIdConfirmation } = storeToRefs(confirmationPopupStore);

//TODO recordar eliminar el id partido, torneo del store
const router = useRouter();
const hasError = ref(false);
const errorMessage = ref("");
const removeMatch = async () => {
  const matchStore = useMatchtore();
  const response = await matchStore.cancelMatch(matchIdConfirmation.value, tournamentIdConfirmation.value);
  if(response.success){
    closePopup();
    hasError.value = false;
    router.go(0)
  }else{
    hasError.value = true;
    errorMessage.value = "No se pudo eliminar el resultado. INTENTE MAS TARDE."
  }
};

const closePopup = () => {
  hasError.value = false;
  confirmationPopupStore.closePopupAction();
}

</script>

<template>
  <v-dialog v-model="showPopup" persistent class="dialog-size">
    <v-card>
      <v-card-title class="text-h5 bg-color">{{ message }}</v-card-title>
      <v-card-text class="text-h2">{{ content }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" @click="removeMatch()" v-if="!hasError">{{ confirmButtonText }}</v-btn>
        <v-btn color="error" variant="flat" @click="closePopup()">Cancelar</v-btn>
      </v-card-actions>
      <v-card-item v-if="hasError" class="bg-color-bottom" justify="center" alignItems="center">
        <v-card-text class="text-h3" >{{errorMessage}}</v-card-text>
      </v-card-item>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.dialog-size {
  margin-left: 20%;
  margin-right: 20%;
}

.bg-color {
  background-color: #122359;
  color: white;
}

.bg-color-bottom {
  background-color: darkred;
  color: white;
}

</style>