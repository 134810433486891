<template>
<v-container class="tournament-body pa-0">
	<h1 class="tournament-title">{{ tournament.name }}</h1>

	<!-- Fases del Torneo con espaciado reducido -->
	<div class="phases-wrapper">
	<div class="phases-container">
		<div v-for="(p, index) in phase" :key="index" class="phase-card">
		<div class="phase-header">Fase {{ index + 1 }}</div>
		<div class="phase-type">{{ p }}</div>
		</div>
	</div>
	</div>

	<!-- Champion Display -->
	<v-fade-transition>
	<div v-if="champion && champion !== ''" class="champion-banner">
		<div class="champion-content">
		<div class="trophy-icon">🏆</div>
		<div class="champion-details">
			<div class="champion-label">CAMPEÓN</div>
			<div class="champion-name">{{ champion }}</div>
		</div>
		<div class="confetti-left">🎉</div>
		<div class="confetti-right">🎊</div>
		</div>
	</div>
	</v-fade-transition>

	<!-- Componentes de Fixture -->
	<v-fade-transition>
	<FixtureGroup
		v-if="phase[0] === 'group' && isDataReady"
		:idT="id"
		:info="tournament[0]"
		:matches_phase_1="matches_phase_1"
		:matches_phase_2="matches_phase_2"
		:champion="champion"
		:classified="classified"
		:sport="sport"
		:info_extra = "tournament"
	/>

	<FixtureLeague
		v-else-if="phase[0] === 'league' && isDataReady"
		:idT="id"
		:info="tournament[0]"
		:matches_phase_1="matches_phase_1"
		:matches_phase_2="matches_phase_2"
		:champion="champion"
		:classified="classified"
		:tab="indexTab"
		:sport="sport"
	/>

	<ShowingPlayoffTabs
		v-else-if="phase[0] === 'playoff' && matches_phase_1.length > 0 && isDataReady"
		:idT="id"
		:info="tournament[0]"
		:matches="matches_phase_1"
		:champion="champion"
		:sport="sport"
	/>
	</v-fade-transition>

	<!-- Alerta de confirmación -->
	<delete-confirmation-alert/>

	<!-- Loading state -->
	<v-overlay v-if="!isDataReady" class="d-flex align-center justify-center">
	<v-progress-circular indeterminate color="primary"></v-progress-circular>
	</v-overlay>
</v-container>
</template>

<script>

/*eslint-disabled*/


import {ref, computed, onMounted} from 'vue';
import {useRouter} from 'vue-router';
import {useTournamentStore} from "@/store/tournament";
import FixtureGroup from './FixtureGroup.vue';
import FixtureLeague from './FixtureLeague.vue';
import ShowingPlayoffTabs from './ShowingPlayoffTabs.vue';
import DeleteConfirmationAlert from "@/components/messageUser/DeleteConfirmationAlert.vue";
import {useTournamentDetailsStore} from "@/store/tournamentDetails";

export default {
name: 'TournamentView',

components: {
	DeleteConfirmationAlert,
	FixtureGroup,
	FixtureLeague,
	ShowingPlayoffTabs,
},

props: {
	id: {
	type: [String, Number],
	required: true
	}
},

setup(props) {
	const router = useRouter();
	const tournamentStore = useTournamentStore();
  const tournamentDetails = useTournamentDetailsStore();
  tournamentDetails.setTournament(props.id);
	// Estado
	const tournament = ref([]);
	const phase = ref([]);
	const matches_phase_1 = ref([]);
	const matches_phase_2 = ref([]);
	const champion = ref('');
	const classified = ref([]);
	const sport = ref('futbol');
	const teams = ref([]);
	const isLoading = ref(true);

	// Computed
	const indexTab = computed(() => router.currentRoute.value.query.tab);
	const isDataReady = computed(() => !isLoading.value && tournament.value.length > 0);

	// Métodos
	const fetchTournamentData = async () => {
	try {
		const response = await tournamentStore.dispatchATournament(props.id);
		const arrayData = response.content;

		arrayData.forEach((element, index) => {
		const phaseNumber = index + 1;
		phase.value.push(element[`phase_${phaseNumber}`].schema);
		tournament.value.push(element[`phase_${phaseNumber}`].positions);
		champion.value = element[`phase_${phaseNumber}`].champion;
		sport.value = element[`phase_${phaseNumber}`].sport;

		if (phaseNumber === 1) {
			classified.value = element[`phase_${phaseNumber}`].classified;
		}
		});
	} catch (error) {
		console.error("Error fetching tournament data:", error);
	}
	};

	const getMatches = async () => {
	try {
		const response = await tournamentStore.getMatchesOfATournament(props.id);
		matches_phase_1.value = response.content.phase_1;
		if (response.content.phase_2) {
		matches_phase_2.value = response.content.phase_2;
		}
	} catch (error) {
		console.error("Error fetching matches:", error);
	}
	};

	const initializeData = async () => {
	isLoading.value = true;
	try {
		await Promise.all([
		fetchTournamentData(),
		getMatches(),
		tournamentStore.getBestScorerOfTournament(props.id),
		tournamentStore.getYellowCardsTournament(props.id),
		tournamentStore.getRedCardsTournament(props.id),
		tournamentStore.getTeamsOfATournament(props.id).then(response => {
			teams.value = response.content;
		})
		]);
	} catch (error) {
		console.error("Error initializing data:", error);
	} finally {
		isLoading.value = false;
	}
	};

	// Lifecycle
	onMounted(() => {
    initializeData();
	});

	return {
	tournament,
	phase,
	matches_phase_1,
	matches_phase_2,
	champion,
	classified,
	sport,
	teams,
	indexTab,
	isDataReady
	};
}
};
</script>

<style scoped>
.tournament-body {
max-width: 1200px;
margin: 0 auto;
padding: 1.5rem;
}

.tournament-title {
text-align: center;
font-size: 1.75rem;
font-weight: 700;
color: #1a237e;
margin: 0.5rem 0;
padding: 0.5rem;
}

.phases-wrapper {
background: linear-gradient(to right, #1a237e, #0d47a1);
padding: 0.75rem;
border-radius: 12px;
margin-bottom: 1rem;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.phases-container {
display: flex;
flex-wrap: wrap;
gap: 0.75rem;
justify-content: center;
}

.phase-card {
background: white;
border-radius: 8px;
overflow: hidden;
min-width: 180px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.phase-header {
background: #2196f3;
color: white;
padding: 0.4rem 0.75rem;
font-weight: 600;
text-align: center;
font-size: 0.95rem;
}

.phase-type {
padding: 0.75rem;
text-align: center;
font-size: 1.1rem;
font-weight: 500;
color: #333;
text-transform: capitalize;
}

/* Champion Display Styles */
.champion-banner {
background: linear-gradient(135deg, #ffd700 0%, #ffa500 100%);
margin: 1rem auto 2rem auto;
border-radius: 16px;
padding: 2px;
max-width: 600px;
position: relative;
box-shadow: 0 8px 32px rgba(255, 215, 0, 0.2);
animation: glow 2s infinite alternate;
}

@keyframes glow {
from {
	box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}
to {
	box-shadow: 0 0 30px rgba(255, 215, 0, 0.6);
}
}

.champion-content {
background: linear-gradient(135deg, #ffffff 0%, #f8f8f8 100%);
border-radius: 14px;
padding: 1.5rem;
display: flex;
align-items: center;
justify-content: center;
position: relative;
overflow: hidden;
}

.trophy-icon {
font-size: 3rem;
margin-right: 1rem;
animation: bounce 2s infinite;
}

@keyframes bounce {
0%, 100% {
	transform: translateY(0);
}
50% {
	transform: translateY(-10px);
}
}

.champion-details {
text-align: center;
}

.champion-label {
font-size: 0.9rem;
font-weight: 600;
color: #b8860b;
letter-spacing: 2px;
margin-bottom: 0.5rem;
}

.champion-name {
font-size: 2rem;
font-weight: 700;
/* Reemplazamos el gradiente con un color sólido más llamativo */
color: #1a237e;
text-transform: uppercase;
letter-spacing: 1px;
/* Añadimos un text-shadow para dar profundidad */
text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.confetti-left, .confetti-right {
position: absolute;
font-size: 2rem;
animation: float 3s infinite alternate;
}

.confetti-left {
left: 1rem;
animation-delay: 0.5s;
}

.confetti-right {
right: 1rem;
animation-delay: 1s;
}

@keyframes float {
0% {
	transform: translateY(0) rotate(0deg);
}
100% {
	transform: translateY(-10px) rotate(10deg);
}
}

/* Responsive Styles */
@media (max-width: 768px) {
.tournament-title {
	font-size: 1.4rem;
	margin: 0.4rem 0;
}

.phases-wrapper {
	padding: 0.5rem;
	margin-bottom: 0.75rem;
}

.phase-card {
	min-width: 140px;
}

.phase-type {
	font-size: 0.95rem;
	padding: 0.5rem;
}

.champion-banner {
	margin: 0.8rem auto 1.5rem auto;
	max-width: 90%;
}

.trophy-icon {
	font-size: 2.5rem;
}

.champion-name {
	font-size: 1.5rem;
}

.confetti-left, .confetti-right {
	font-size: 1.5rem;
}
}

@media (max-width: 480px) {
.champion-content {
	padding: 1rem;
}

.trophy-icon {
	font-size: 2rem;
}

.champion-name {
	font-size: 1.2rem;
}
}
</style>