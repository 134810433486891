// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../components/HomeView.vue';
import TournamentDetails from '../components/TournamentDetails.vue';
import CreateTournament from '../components/CreateTournament.vue';
import AddTeam from '../components/AddTeam.vue';
import ShowMatch from '../components/ShowMatch.vue';
import ShowTeam from '../components/ShowTeam.vue';
import HistoricTournaments from '../components/HistoricTournaments.vue';
import Register from '../components/UserRegister.vue';
import Login from '../components/UserLogin.vue';
import ShowTeamsOfTournament from '../components/ShowTeamsOfTournament.vue';
import ShowPlayer from '../components/ShowPlayer.vue';
import ShowTeamAsPlayer from '../components/ShowTeamAsPlayer.vue';

import {useAuthStore} from "@/store/auth";

import MatchResult from "@/components/MatchResult.vue";
import MainSession from "@/components/managerUser/MainSession.vue";
import HomeAccountingRecord from "@/components/accountingRecord/HomeAccountingRecord.vue";
import AddPayment from "@/components/accountingRecord/AddPayment.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login,
    props: false,
  },
  {
    path: "/tournament",
    component: MainSession,
    children: [
      {
        path: '/home',
        name: 'Tournament',
        component: HomeView,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {
        path: '/tournament/:id',
        name: 'TournamentDetails',
        component: TournamentDetails,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {
        path: '/createTournament',
        name: 'CreateTournament',
        component: CreateTournament,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {
        path: '/addTeam',
        name: 'AddTeam',
        component: AddTeam,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {
        path: '/updateTeam/:teamId',
        name: 'UpdateTeam',
        component: AddTeam,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {
        path: '/showMatch/:tournamentId/:matchId',
        name: 'showMatch',
        component: ShowMatch,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {
        path: '/showTeam/:teamId',
        name: 'ShowTeam',
        component: ShowTeam,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {
        path: '/historicTournaments',
        name: 'HistoricTournaments',
        component: HistoricTournaments,
        props: true,
        meta: {requiresAuth: true}, // Esta ruta requiere autenticación

      },
      {path: '/register', component: Register, name: "UserRegister"},
      {
        path: '/match/:matchId/:tournamentId',
        name: 'matchResult',
        component: MatchResult,
        props: true,
      },
    {
        path: '/teams',
        name: 'ShowTeams',
        component: ShowTeamsOfTournament,
        props: true,
    },
    {
        path: '/showPlayer/:playerId',
        name: 'ShowPlayer',
        component: ShowPlayer,
        props: true,
    },
    {
        path: '/showTeamAsPlayer/:teamId',
        name: 'ShowTeamAsPlayer',
        component: ShowTeamAsPlayer,
        props: true,
    },
    {
        path: '/accountingRecord',
        name: 'accountingRecord',
        component: HomeAccountingRecord,
        props: true,
    },
      {
        path: '/addPayment',
        name: 'addPayment',
        component: AddPayment,
        props: true,
    },

    ]
  },
  {path: '/tournament/login', component: Login, name: "LoginAdmin"},
  // Puedes agregar más rutas según necesites
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let isLoggedIn = false; // Comprueba si hay un token en localStorage
  if(useAuthStore().token){
    isLoggedIn = useAuthStore().token.value !== '' || useAuthStore().token.value !== null;
  }
  // Si la ruta requiere autenticación y el usuario no está logueado, redirige al login
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next({ name: 'LoginAdmin' });
  } else {
    next(); // Permite acceder a la ruta
  }
});



export default router;