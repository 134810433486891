<template>
  <!-- Desktop Menu -->
  <v-app-bar app color="primary" dark v-if="!isMobile" elevation="4">
    <v-avatar
        @click="goToHome()"
        class="cursor-pointer mr-4"
        style="width: 48px; height: 48px; background: white;"
    >
      <v-img :src="require('../assets/logoMainTextUnder.svg')" alt="Logo" cover/>
    </v-avatar>

    <v-btn @click="goToHome()">Home</v-btn>
    <v-btn @click="goToShowTeams()">Equipos</v-btn>
    <v-btn @click="goAccountingRegister()">Registro Contable</v-btn>

    <!-- Settings Menu -->
    <v-menu v-if="is_able" offset-y>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props">Configuracion</v-btn>
      </template>
      <v-list>
        <v-list-item @click="goToCreateTournament()" link>
          <v-list-item-title>Crear Torneo</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goToAddTeam()" link>
          <v-list-item-title>Agregar Equipo</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer></v-spacer>
    <v-btn @click="logout">Logout</v-btn>
  </v-app-bar>

  <!-- Mobile Menu -->
  <div v-if="isMobile" class="mobile-header-container">
    <v-app-bar app color="primary" dark elevation="4">
      <v-menu offset-y>
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon v-bind="props"></v-app-bar-nav-icon>
        </template>

        <v-list class="mobile-menu-list">
          <v-list-item @click="goToHome()" link>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goToHistoricTournaments()" link>
            <v-list-item-title>Torneos Histórico</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goToShowTeams()" link>
            <v-list-item-title>Equipos</v-list-item-title>
          </v-list-item>

          <v-divider v-if="is_able"></v-divider>

          <template v-if="is_able">
            <v-list-item @click="goToCreateTournament()" link>
              <v-list-item-title>Crear Torneo</v-list-item-title>
            </v-list-item>

            <v-list-item @click="goToAddTeam()" link>
              <v-list-item-title>Agregar Equipo</v-list-item-title>
            </v-list-item>

            <v-list-item @click="goAccountingRegister()" link>
              <v-list-item-title>Registro Contable</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>

      <v-avatar
          @click="goToHome()"
          class="mobile-logo cursor-pointer"
      >
        <v-img :src="require('../assets/logoMainTextUnder.svg')" alt="Logo" cover/>
      </v-avatar>

      <v-spacer></v-spacer>

      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/auth";

export default {
  name: 'MenuComponent',

  data() {
    return {
      is_able: useAuthStore().isAble
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },

  methods: {
    goToHome() {
      this.$router.push({ name: 'Tournament' });
    },

    goToHistoricTournaments() {
      this.$router.push({ name: 'HistoricTournaments' });
    },

    goToCreateTournament() {
      this.$router.push({ name: 'CreateTournament' });
    },

    goToAddTeam() {
      this.$router.push({ name: 'AddTeam' });
    },

    goToShowTeams() {
      this.$router.push({ name: 'ShowTeams' });
    },

    logout() {
      useAuthStore().logout();
      this.$router.push({ name: 'LoginAdmin' });
    },

    goAccountingRegister() {
      this.$router.push({ name: 'accountingRecord' });
    }
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.mobile-logo {
  width: 40px !important;
  height: 40px !important;
  transition: transform 0.2s ease;
  background: white;
}

.mobile-logo:active {
  transform: scale(0.95);
}

.mobile-menu-list {
  min-width: 200px;
}

.mobile-header-container {
  position: relative;
}
</style>