<template>
<v-container class="player-stats">
	<v-card class="mx-auto" elevation="2">
	<!-- Player Header -->
	<v-card-item class="text-center py-4" style="background: linear-gradient(to right, #1a237e, #283593)">
		<v-card-title class="text-h4 text-white font-weight-bold">
		<v-icon icon="mdi-account" size="x-large" class="mr-2"></v-icon>
		{{ player_name }}
		</v-card-title>
	</v-card-item>

	<!-- Tournament Stats -->
	<v-card-text class="pa-4">
		<v-expansion-panels v-if="player_info_by_tournament?.length">
		<v-expansion-panel
			v-for="t_info in player_info_by_tournament"
			:key="t_info.id"
			class="mb-4"
		>
			<v-expansion-panel-title class="text-h6">
			<v-icon icon="mdi-trophy" class="mr-2"></v-icon>
			{{ t_info.tournament_name }}
			</v-expansion-panel-title>
			
			<v-expansion-panel-text>
			<v-row class="mt-2">
				<!-- Stats Cards -->
				<v-col cols="12" sm="6" md="3">
				<v-card variant="outlined" class="text-center pa-4">
					<v-icon icon="mdi-star" color="amber" size="large" class="mb-2"></v-icon>
					<div class="text-h5 font-weight-bold mb-1">{{ t_info.cantidad_mvp }}</div>
					<div class="text-subtitle-2">MVPs</div>
				</v-card>
				</v-col>

				<v-col cols="12" sm="6" md="3">
				<v-card variant="outlined" class="text-center pa-4">
					<v-icon icon="mdi-soccer" color="primary" size="large" class="mb-2"></v-icon>
					<div class="text-h5 font-weight-bold mb-1">{{ t_info.goles }}</div>
					<div class="text-subtitle-2">Goles</div>
				</v-card>
				</v-col>

				<v-col cols="12" sm="6" md="3">
				<v-card variant="outlined" class="text-center pa-4">
					<v-icon icon="mdi-card" color="warning" size="large" class="mb-2"></v-icon>
					<div class="text-h5 font-weight-bold mb-1">{{ t_info.amarillas }}</div>
					<div class="text-subtitle-2">Tarjetas Amarillas</div>
				</v-card>
				</v-col>

				<v-col cols="12" sm="6" md="3">
				<v-card variant="outlined" class="text-center pa-4">
					<v-icon icon="mdi-card" color="error" size="large" class="mb-2"></v-icon>
					<div class="text-h5 font-weight-bold mb-1">{{ t_info.rojas }}</div>
					<div class="text-subtitle-2">Tarjetas Rojas</div>
				</v-card>
				</v-col>
			</v-row>

			</v-expansion-panel-text>
		</v-expansion-panel>
		</v-expansion-panels>

		<v-alert
		v-else
		type="info"
		variant="tonal"
		class="mt-4"
		>
		No hay estadísticas disponibles para este jugador.
		</v-alert>
	</v-card-text>
	</v-card>
</v-container>
</template>

<script>
import axios from 'axios';
import { useAuthStore } from "@/store/auth";

export default {
name: 'ShowPlayer',

props: {
	playerId: {
	type: String,
	required: true
	}
},

data() {
	return {
	player_info_by_tournament: null,
	player_name: null,
	loading: false,
	error: null
	};
},

created() {
	this.fetchPlayerProfile();
},

methods: {
	async fetchPlayerProfile() {
	this.loading = true;
	try {
		const authStore = useAuthStore();
		const token = authStore.decryptPassword(authStore.token);

		const response = await axios.get(
		`${process.env.VUE_APP_API_ENDPOINT}showProfilePlayer/${this.playerId}`,
		{
			headers: {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json'
			}
		}
		);

		this.player_info_by_tournament = response.data;
		this.player_name = response.data[0]?.player_name;
	} catch (error) {
		console.error('Error fetching player profile:', error);
		this.error = 'Error al cargar el perfil del jugador';
	} finally {
		this.loading = false;
	}
	}
}
};
</script>

<style scoped>
.player-stats {
max-width: 1200px;
margin: 0 auto;
padding: 16px;
}

@media (max-width: 600px) {
.player-stats {
	padding: 8px;
}
}

.v-expansion-panel {
border-radius: 8px !important;
}

.v-expansion-panel-title {
background: linear-gradient(to right, #f5f5f5, #ffffff) !important;
}

.player-stats {
max-width: 1200px;
margin: 0 auto;
padding: 16px;
}

.v-card-title {
color: white !important;
}
</style>