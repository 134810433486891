<script setup>
/* eslint-disable */
import { useAlertStore} from "@/models/useErrorHandling";
import {computed} from "vue";

const internalShow = useAlertStore();


const internalErrorMessage = computed(() => internalShow.errorMessage ? internalShow.errorMessage : '');


</script>

<template>
  <v-snackbar v-model="internalShow.showError" :color="internalShow.errorType" :timeout="4000">
    {{ internalErrorMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs">Cerrar</v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>

</style>