import { ref } from 'vue';
import {defineStore} from "pinia";

export const useAlertStore = defineStore("alertStore", () => {

    const showError = ref(false);
    const errorMessage = ref('Datos incompletos');
    const errorType = ref('error');

    function handleError(message) {
        showError.value = true;
        errorMessage.value = message;
    }


    const showPopup = ref(false);
    const message = ref('');
    const content = ref('');
    const matchIdConfirmation = ref(null);
    const tournamentIdConfirmation = ref(null);
    const confirmButtonText = ref('');
    // const onConfirm = ref(null);
    // const onCancel = ref(null);

    function showConfirmationAlert(aMessage, aContent, aConfirmButtonText) {
        showPopup.value = true;
        message.value = aMessage;
        content.value = aContent;
        confirmButtonText.value = aConfirmButtonText;
    }

    function closePopupAction() {
        showPopup.value = false;
    }

    // function confirmAction() {
    //     onConfirm.value();
    //     closePopupAction();
    // }
    //
    // function cancelAction() {
    //     onCancel.value();
    //     closePopupAction();
    // }

    function setMatchConfirmation (aMatchConfirmation, aTournamentId) {
        matchIdConfirmation.value = aMatchConfirmation;
        tournamentIdConfirmation.value = aTournamentId;
    }

    return {
        showError,
        errorMessage,
        errorType,
        handleError,
        showConfirmationAlert,
        message,
        content,
        confirmButtonText,
        // onConfirm,
        // onCancel,
        matchIdConfirmation,
        tournamentIdConfirmation,
        setMatchConfirmation,
        showPopup,
        closePopupAction
    };
})