	<template>
	<v-container class="fill-height pa-0" fluid>
		<v-row class="fill-height ma-0" style="width: 100%">
		<v-col cols="12" class="d-flex justify-center align-center pa-0">
			<v-card elevation="12" class="login-card">
			<!-- Card Header -->
			<v-card-title class="text-center pt-8 pb-2">
				<h2 class="text-h4 font-weight-bold primary--text w-100">
				Bienvenido
				</h2>
			</v-card-title>
			
			<v-card-subtitle class="text-center pb-4">
				Ingresa a tu cuenta
			</v-card-subtitle>

			<!-- Login Form -->
			<v-card-text class="pt-2">
				<v-form @submit.prevent="loginUser" ref="form" class="px-4">
				<!-- Email Field -->
				<v-text-field
					v-model="form.email"
					:rules="[rules.required, rules.email]"
					label="Email"
					prepend-inner-icon="mdi-email"
					type="email"
					color="primary"
					outlined
					dense
					autocomplete="email"
					class="mb-4"
				/>

				<!-- Password Field -->
				<v-text-field
					v-model="form.password"
					:rules="[rules.required, rules.min]"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showPassword ? 'text' : 'password'"
					label="Contraseña"
					prepend-inner-icon="mdi-lock"
					color="primary"
					outlined
					dense
					autocomplete="current-password"
					class="mb-6"
					@click:append="showPassword = !showPassword"
				/>

				<!-- Login Button -->
				<v-btn
					type="submit"
					color="primary"
					block
					x-large
					elevation="2"
					:loading="isLoading"
					:disabled="isLoading"
					class="mb-4 py-6"
					height="44"
				>
					{{ isLoading ? 'Iniciando sesión...' : 'Iniciar Sesión' }}
				</v-btn>


				</v-form>
			</v-card-text>
			</v-card>

			<!-- Error Snackbar -->
			<v-snackbar
			v-model="showError"
			color="error"
			timeout="3000"
			bottom
			>
			{{ errorMessage }}
			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="showError = false"
				>
				Cerrar
				</v-btn>
			</template>
			</v-snackbar>
		</v-col>
		</v-row>
	</v-container>
	</template>

	<script>
	import { useAuthStore } from "@/store/auth";

	export default {
	name: 'LoginView',

	data() {
		return {
		form: {
			email: "",
			password: "",
		},
		showPassword: false,
		isLoading: false,
		showError: false,
		errorMessage: '',
		rules: {
			required: v => !!v || 'Este campo es requerido',
			min: v => v.length >= 6 || 'La contraseña debe tener al menos 6 caracteres',
			email: v => /.+@.+\..+/.test(v) || 'Ingresa un email válido'
		}
		};
	},

	async mounted() {
		const authStore = useAuthStore();
		if (authStore.isAuthenticated) {
		this.$router.push({ name: 'Tournament' });
		}
	},

	methods: {
		async loginUser() {
		try {
			if (!this.$refs.form.validate()) return;

			this.isLoading = true;
			const authStore = useAuthStore();
			const response = await authStore.login(this.form.email, this.form.password);
			
			if (response.success) {
			const roleResponse = await authStore.isAbleUser();
			if (!roleResponse.success) {
				this.showErrorMessage('No se pudo verificar el rol del usuario');
				return;
			}
			this.$router.push({ name: 'Tournament' });
			} else {
			this.showErrorMessage('Credenciales inválidas');
			}
		} catch (error) {
			this.showErrorMessage('Ocurrió un error al iniciar sesión');
			console.error('Login error:', error);
		} finally {
			this.isLoading = false;
		}
		},

		showErrorMessage(message) {
		this.errorMessage = message;
		this.showError = true;
		},

		forgotPassword() {
		// Implementar la lógica para recuperar contraseña
		console.log('Recuperar contraseña');
		}
	}
	};
	</script>

	<style scoped>
	.container {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	min-height: 100vh !important;
	width: 100vw !important;
	max-width: 100vw !important;
	margin: 0 !important;
	padding: 0 !important;
	}

	.login-card {
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
	background: linear-gradient(to right bottom, #ffffff, #f8f9fa);
	}

	.v-card__title {
	position: relative;
	}

	.v-card__title::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 60px;
	height: 3px;
	background-color: var(--v-primary-base);
	border-radius: 3px;
	}

	/* Animaciones para los campos de texto */
	.v-text-field {
	transition: transform 0.2s ease;
	}

	.v-text-field:focus-within {
	transform: translateY(-2px);
	}

	/* Estilo para el botón de login */
	.v-btn:not(.v-btn--text) {
	text-transform: none;
	letter-spacing: 0.5px;
	font-weight: 600;
	transition: transform 0.2s ease;
	}

	.v-btn:not(.v-btn--text):hover {
	transform: translateY(-2px);
	}

	/* Responsive adjustments */
	@media (max-width: 600px) {
	.login-card {
		margin: 1rem;
		width: calc(100% - 2rem);
	}
	}

	/* Asegurar que el row tome todo el ancho disponible */
	.row {
	width: 100% !important;
	margin: 0 !important;
	}

	/* Asegurar que la columna tome todo el espacio necesario */
	.col {
	display: flex;
	justify-content: center;
	padding: 0;
	}
	</style>