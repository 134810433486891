import {defineStore} from "pinia";
import {computed, ref} from 'vue';

export const useTournamentDetailsStore = defineStore("tournamentDetailsStore", () => {
    const sport = ref('');
    const isFootball = computed(() => sport.value === 'futbol');
    const isTennis = computed(() => sport.value === 'tenis' || sport.value === 'padel');
    const idTournament = ref(null);
    // Actions
    const setSport = (value) => {
        sport.value = value;
    };

    const clearSport = () => {
        sport.value = '';
    };

    const setTournament = (id) => {
        idTournament.value = id;
    }

    const clearTournament = () => {
        idTournament.value = null;
    };

    return {
        sport,
        clearSport,
        setSport,
        isFootball,
        isTennis,
        setTournament,
        idTournament,
        clearTournament
    }
})