<template>
  <div class="tournament-container">
    <!-- Navigation tabs with icons -->
    <nav class="tabs-navigation">
      <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab-button', { 'active': selectedTab === index }]"
          @click="selectedTab = index"
      >
        <span class="tab-icon">
          {{ getTabIcon(index) }}
        </span>
        <span class="tab-text">{{ tab }}</span>
      </button>
    </nav>
    <!-- Tab Content -->
    <div class="content-wrapper">
      <!-- Draw Tab -->
      <transition name="fade" mode="out-in">
        <div v-if="selectedTab === 0" class="tab-panel">
          <FixturePlayoff
              v-if="matches.length > 0"
              :idT="idT"
              :info="info"
              :matches="matches"
              :champion="champion"
          />
        </div>
      </transition>

      <!-- Scorers Tab -->
      <transition name="fade" mode="out-in">
        <div v-if="selectedTab === 1 && sport === 'futbol'" class="tab-panel">
          <MaxScorers :idTournament="idT" />
        </div>
      </transition>

      <!-- Match Grid Tab -->
      <transition name="fade" mode="out-in">
        <div
            v-if="(selectedTab === 2 && sport === 'futbol') || (selectedTab === 1 && sport !== 'futbol')"
            class="tab-panel grid-panel"
        >
          <GrillaMatchesByDatePlayoff
              v-if="matches.length > 0"
              :matches="matches"
              class="centered-grid"
          />
        </div>
      </transition>

      <!-- Teams Tab -->
      <transition name="fade" mode="out-in">
        <div
            v-if="(selectedTab === 3 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')"
            class="tab-panel"
        >
          <ShowTeamsOfTournament :tournamentId="idT" />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div
            v-if="(selectedTab === 4 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')"
            class="tab-panel"
        >
          <SuspensionsPlayer/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GrillaMatchesByDatePlayoff from './GrillaMatchesByDatePlayoff.vue';
import MaxScorers from './MaxScorers.vue';
import FixturePlayoff from './FixturePlayoff.vue';
import ShowTeamsOfTournament from './ShowTeamsOfTournament.vue';
import { useTournamentDetailsStore } from "@/store/tournamentDetails";
import SuspensionsPlayer from "@/components/SuspensionsPlayer.vue";

export default {
  name: 'TournamentTabs',
  computed: {
    containerHeight() {
      let baseHeight = 500;
      return baseHeight * (this.rounds + 1) + 'px';
    }
  },
  components: {
    SuspensionsPlayer,
    MaxScorers,
    FixturePlayoff,
    GrillaMatchesByDatePlayoff,
    ShowTeamsOfTournament
  },
  props: {
    idT: { required: true },
    info: { required: true },
    matches: { required: true },
    champion: { required: true },
    sport: { required: true }
  },
  data() {
    return {
      selectedTab: 0,
      tabs: []
    };
  },
  methods: {
    getTabIcon(index) {
      const icons = {
        0: '🏆',
        1: '📊',
        2: '📅',
        3: '👥',
        4: ''
      };
      return icons[index] || '📋';
    }
  },
  mounted() {
    const detailsStore = useTournamentDetailsStore();
    detailsStore.setSport(this.sport);
    this.tabs = this.sport === 'futbol'
        ? ['Draw', 'Estadísticas', 'Grilla de Partidos', 'Equipos', 'Sanciones']
        : ['Draw', 'Grilla de Partidos', 'Jugadores'];
  }
};
</script>

<style scoped>
.tournament-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

.tabs-navigation {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  background: linear-gradient(to right, #1a237e, #0d47a1);
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  background: transparent;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.tab-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.tab-button.active {
  background: #2196f3;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.3);
}

.tab-icon {
  font-size: 1.25rem;
}

.content-wrapper {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tab-panel {
  padding: 2rem;
  overflow: visible;
  position: relative;
  background: transparent;
  box-shadow: none;
  overflow-y: auto;
  max-height: 2500px;
}

/* New styles for grid centering */
.grid-panel {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 400px;
}

.centered-grid {
  margin: 0 auto;
  max-width: 100%;
  width: fit-content;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .tournament-container {
    padding: 1rem;
  }

  .tabs-navigation {
    flex-wrap: wrap;
  }

  .tab-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}
</style>