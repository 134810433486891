<template>
    <div class="main-container">
      <div class="tournaments-container">
        <!-- Add Tournament Card -->
        <div 
          v-if="is_able" 
          class="tournament-card add-tournament"
          @click="goToCreateTournament"
        >
          <div class="tournament-header">
            <h2>Agregar torneo</h2>
          </div>
          <div class="tournament-details" align="center">
            <v-img 
              :src="require('../assets/plus-add.png')"
              :width="100"
              aspect-ratio="4/3"
              cover
              class="add-icon"
            />
          </div>
        </div>
  
        <!-- Tournament Cards -->
        <div
          v-for="tournament in tournaments"
          :key="tournament.id"
          class="tournament-card"
          @click="goToTournament(tournament.id)"
        >
          <div class="tournament-header">
            <h2>{{ tournament.name_of_tournament }}</h2>
          </div>
          
          <div class="tournament-details">
            <div class="detail-row">
              <v-icon icon="mdi-soccer" class="detail-icon" />
              <span>Deporte: <b>{{ tournament.sport }}</b></span>
            </div>
            
            <div class="detail-row">
              <v-icon icon="mdi-calendar" class="detail-icon" />
              <span>{{ tournament.date_begin }}</span>
            </div>
            
            <div class="detail-row">
              <v-icon icon="mdi-trophy" class="detail-icon" />
              <span>{{ tournament.schema_value }}</span>
            </div>
            
            <div v-if="tournament.champion" class="detail-row champion-row">
              <v-icon icon="mdi-crown" class="detail-icon champion-icon" />
              <span>{{ tournament.champion }}</span>
            </div>
            
            <div class="detail-row">
              <v-icon icon="mdi-flag" class="detail-icon" />
              <span>Fase actual: {{ tournament.current_phase }}</span>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Loading State -->
      <div v-if="isLoading" class="loading-state">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
        <span class="loading-text">Cargando torneos...</span>
      </div>
  
      <!-- Empty State -->
      <div v-if="!isLoading && tournaments.length === 0" class="empty-state">
        <v-icon icon="mdi-trophy-outline" size="64" color="grey-lighten-1" />
        <h3>No hay torneos disponibles</h3>
        <p v-if="is_able">Haga click en "Agregar torneo" para crear uno nuevo</p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useTournamentStore } from "@/store/tournament";
  import { useAuthStore } from "@/store/auth";
  
  export default {
    name: 'TournamentList',
  
    setup() {
      const router = useRouter();
      const tournaments = ref([]);
      const isLoading = ref(false);
      const is_able = ref(useAuthStore().isAble);
  
      const goToTournament = (id) => {
        router.push({ 
          name: 'TournamentDetails', 
          params: { id } 
        });
      };
  
      const goToCreateTournament = () => {
        router.push({ name: 'CreateTournament' });
      };
  
      const fetchTournamentData = async () => {
        isLoading.value = true;
        try {
          const tournamentStore = useTournamentStore();
          const response = await tournamentStore.dispatchGetTournaments();
          
          if (response.success) {
            tournaments.value = response.content;
          } else {
            console.error("Error al obtener los torneos:", response.error);
          }
        } catch (error) {
          console.error("Error al cargar los torneos:", error);
        } finally {
          isLoading.value = false;
        }
      };
  
      onMounted(() => {
        fetchTournamentData();
      });
  
      return {
        tournaments,
        isLoading,
        is_able,
        goToTournament,
        goToCreateTournament
      };
    }
  };
  </script>
  
  <style scoped>
  .main-container {
    font-family: 'Arial', sans-serif;
    margin-top: 2%;
    padding: 0 20px;
  }
  
  .tournaments-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 20px;
  }
  
  .tournament-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    overflow: hidden;
  }
  
  .tournament-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.2);
  }
  
  .tournament-header {
    background: linear-gradient(to right, #1a237e, #0d47a1);
    color: white;
    padding: 16px;
  }
  
  .tournament-header h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .tournament-details {
    padding: 16px;
  }
  
  .detail-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    color: #4b5563;
  }
  
  .detail-icon {
    color: #6b7280;
    font-size: 20px;
  }
  
  .champion-row {
    background: linear-gradient(to right, #fef3c7, #fde68a);
    margin: 8px -16px;
    padding: 8px 16px;
  }
  
  .champion-icon {
    color: #d97706;
  }
  
  .add-tournament {
    border: 2px dashed #d1d5db;
    background-color: #f9fafb;
  }
  
  .add-tournament:hover {
    border-color: #2563eb;
    background-color: #f0f9ff;
  }
  
  .add-icon {
    opacity: 0.6;
    transition: opacity 0.2s ease;
  }
  
  .add-tournament:hover .add-icon {
    opacity: 1;
  }
  
  .loading-state, .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    gap: 16px;
    text-align: center;
  }
  
  .loading-text {
    color: #6b7280;
    margin-top: 16px;
  }
  
  .empty-state h3 {
    color: #374151;
    margin: 16px 0 8px;
  }
  
  .empty-state p {
    color: #6b7280;
  }
  
  @media (max-width: 640px) {
    .tournaments-container {
      grid-template-columns: 1fr;
      padding: 16px;
    }
  
    .tournament-card {
      width: 100%;
    }
  }
  </style>