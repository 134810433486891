<template>
<div class="tournament-container">
	<!-- Navegación de tabs con iconos -->
	<nav class="tabs-navigation">
	<button
		v-for="(tab, index) in tabs"
		:key="index"
		:class="['tab-button', { 'active': selectedTab === index }]"
		@click="selectedTab = index"
	>
		<span class="tab-icon">
		{{ getTabIcon(index) }}
		</span>
		<span class="tab-text">{{ tab }}</span>
	</button>
	</nav>

	<!-- Contenedor principal con efecto de fade -->
	<div class="content-wrapper">
	<!-- Tab de Liga -->
	<transition name="fade" mode="out-in">
		<div v-if="selectedTab === 0" class="tab-panel">
		<!-- Sección de Campeón -->

		<!-- Tabla de Posiciones -->
		<div class="league-table-container">
			<h2 class="section-title">Tabla de la liga</h2>
			<v-data-table
			:headers="headers"
			:items="groups"
			:loading="tableOptions.loading"
			density="comfortable"
			class="elevation-2 rounded-lg"
			:items-per-page="-1"
			hide-default-footer
			>
			<template v-slot:header="{ header }">
				<tr class="header-row">
				<th v-for="h in header" :key="h.key" class="header-cell">
					{{ h.title }}
				</th>
				</tr>
			</template>

			<template v-slot:item="{ item, index }">
				<tr 
				:class="[
					'table-row',
					{ 'qualified': isClassified(item.position) },
					{ 'row-alternate': index % 2 === 0 }
				]"
				>
				<td class="position-cell">{{ item.position }}</td>
				<td class="team-cell">
					<span class="team-name">{{ item.name }}</span>
				</td>
				<td class="stats-cell"><b>{{ item.stats?.points || 0 }}</b></td>
				<td class="stats-cell">{{ item.stats?.pj || 0 }}</td>
				<td class="stats-cell">{{ item.stats?.partidos_ganados || 0 }}</td>
				<td class="stats-cell">{{ item.stats?.partidos_empatados || 0 }}</td>
				<td class="stats-cell">{{ item.stats?.partidos_perdidos || 0 }}</td>
				<td v-show="!isDesktop" class="stats-cell">{{ item.stats?.goles || 0 }}</td>
				<td v-show="!isDesktop" class="stats-cell">{{ item.stats?.goles_contra || 0 }}</td>
				<td v-show="!isDesktop" class="stats-cell">
					{{ item.stats ? item.stats.goles - item.stats.goles_contra : 0 }}
				</td>
				</tr>
			</template>
			</v-data-table>
		</div>

		<!-- Fixture Playoff si existe -->
		<FixturePlayoff
			v-if="matches_phase_2.length > 0"
			:idT="idT"
			:info="info"
			:matches="matches_phase_2"
			:champion="champion"
			class="mt-8"
		/>
		</div>
	</transition>

	<!-- Tab de Estadísticas -->
	<transition name="fade" mode="out-in">
		<div v-if="selectedTab === 1 && sport === 'futbol'" class="tab-panel">
		<MaxScorers :idTournament="idT" />
		</div>
	</transition>

	<!-- Tab de Grilla de Partidos -->
	<transition name="fade" mode="out-in">
		<div 
		v-if="(selectedTab === 2 && sport === 'futbol') || (selectedTab === 1 && sport !== 'futbol')"
		class="tab-panel"
		>
		<div class="matches-grid">
			<GrillaMatchesByDateLeague :matches="matches_phase_1" class="grilla-component"/>
			<GrillaMatchesByDatePlayoff
			v-if="this.matches_phase_2 && 
							Object.keys(this.matches_phase_2).length > 0"
			:matches="matches_phase_2"
      class="grilla-component"
			/>
		</div>
		</div>
	</transition>

	<!-- Tab de Equipos -->
	<transition name="fade" mode="out-in">
		<div 
		v-if="(selectedTab === 3 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')"
		class="tab-panel"
		>
		<ShowTeamsOfTournament :tournamentId="idT" />
		</div>
	</transition>

    <transition name="fade" mode="out-in">
      <div
          v-if="(selectedTab === 4 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')"
          class="tab-panel"
      >
        <SuspensionsPlayer/>
      </div>
    </transition>

	</div>
</div>
</template>

<script>
import GrillaMatchesByDateLeague from './GrillaMatchesByDateLeague.vue';
import GrillaMatchesByDatePlayoff from './GrillaMatchesByDatePlayoff.vue';
import MaxScorers from './MaxScorers.vue';
import FixturePlayoff from './FixturePlayoff.vue';
import ShowTeamsOfTournament from './ShowTeamsOfTournament.vue';
import { useDisplay } from 'vuetify';
import { useTournamentDetailsStore } from "@/store/tournamentDetails";
import SuspensionsPlayer from "@/components/SuspensionsPlayer.vue";

export default {
	name: 'TournamentTabs',

	components: {
    SuspensionsPlayer,
		GrillaMatchesByDateLeague,
		MaxScorers,
		FixturePlayoff,
		GrillaMatchesByDatePlayoff,
		ShowTeamsOfTournament,
	},
	props: {
		idT: { required: true },
		info: { required: true },
		matches_phase_1: { required: true },
		matches_phase_2: { required: true },
		champion: { required: true },
		classified: { required: true },
		sport: { required: true }
	},
	setup() {
		const { isDesktop } = useDisplay();
		return { isDesktop };
	},
	data() {
		return {
			selectedTab: 0,
			tabs: [],
			tableOptions: {
			loading: false,
			sortBy: [{ key: 'name', order: 'asc' }],
			tableKey: new Date().getUTCMilliseconds(),
			},
			headers: [
			{ key: 'position', title: 'Pos', align: 'center', width: '50px' },
			{ key: 'name', title: 'Equipo', align: 'left' },
			{ key: 'points', title: 'Pts', align: 'center', width: '60px' },
			{ key: 'pj', title: 'PJ', align: 'center', width: '60px' },
			{ key: 'pg', title: 'PG', align: 'center', width: '60px' },
			{ key: 'pe', title: 'PE', align: 'center', width: '60px' },
			{ key: 'pp', title: 'PP', align: 'center', width: '60px' },
			{ key: 'gf', title: 'GF', align: 'center', width: '60px', class: 'd-none d-lg-table-cell' },
			{ key: 'gc', title: 'GC', align: 'center', width: '60px', class: 'd-none d-lg-table-cell' },
			{ key: 'dif', title: 'DIF', align: 'center', width: '60px', class: 'd-none d-lg-table-cell' }
			],
			groups: []
		};
	},
	methods: {
		getTabIcon(index) {
			const icons = {
			0: '🏆', // Liga
			1: '📊', // Estadísticas
			2: '📅', // Grilla de partidos
			3: '👥',  // Equipos
        4:''
			};
			return icons[index] || '📋';
		},
		isClassified(position) {
			return this.classified && position <= this.classified;
		}
	},
	mounted() {
		const detailsStore = useTournamentDetailsStore();
		detailsStore.setSport(this.sport);
		this.groups = this.info;
		
		this.tabs = this.sport === 'futbol'
			? ['Liga', 'Estadísticas', 'Grilla de Partidos', 'Equipos', 'Sanciones']
			: ['Liga', 'Grilla de Partidos', 'Jugadores'];
	}
};
</script>

<style scoped>
.tournament-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 1.5rem;
	overflow: visible;
}

.tabs-navigation {
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	background: linear-gradient(to right, #1a237e, #0d47a1);
	padding: 1rem;
	border-radius: 12px;
	margin-bottom: 2rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.tab-button {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.75rem 1.5rem;
	border: none;
	border-radius: 8px;
	background: transparent;
	color: white;
	cursor: pointer;
	transition: all 0.3s ease;
	font-weight: 500;
}

.tab-button:hover {
	background: rgba(255, 255, 255, 0.1);
	transform: translateY(-1px);
}

.tab-button.active {
	background: #2196f3;
	box-shadow: 0 2px 4px rgba(33, 150, 243, 0.3);
}

.tab-icon {
	font-size: 1.25rem;
}

.content-wrapper {
	background: transparent;
	border-radius: 12px;
	overflow: visible;
	box-shadow: none;
}

.tab-panel {
	padding: 2rem;
	height: auto;
	min-height: 100%;
	overflow: visible;
	position: relative;
	background: transparent;
	box-shadow: none;
}

.champion-banner {
	background: linear-gradient(135deg, #ffd700, #ffa000);
	border-radius: 12px;
	padding: 2rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	color: white;
	text-align: center;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.trophy-icon {
	font-size: 2.5rem;
}

.champion-title {
	font-size: 1.5rem;
	margin: 0;
}

.champion-name {
	display: block;
	font-size: 2rem;
	font-weight: 700;
	margin-top: 0.5rem;
}

.section-title {
	font-size: 1.5rem;
	color: #1a237e;
	margin-bottom: 1.5rem;
	text-align: center;
}

.league-table-container {
	background: white;
	border-radius: 12px;
	overflow: hidden;
	margin-bottom: 2rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.header-row {
	background: #1a237e !important;
}

.header-cell {
	color: white !important;
	font-weight: 600;
	padding: 1rem !important;
}

.table-row {
	transition: background-color 0.2s ease;
}

.table-row:hover {
	background-color: #f5f5f5;
}

.qualified {
	background-color: #e3f2fd;
}

.row-alternate {
	background-color: #fafafa;
}

.position-cell {
	font-weight: 700;
	color: #1a237e;
}

.team-cell {
	font-weight: 500;
}

.stats-cell {
	text-align: center;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.v-data-table__wrapper {
	margin-bottom: 0 !important;
}

@media (max-width: 768px) {
	.tournament-container {
	padding: 1rem;
	}

	.tabs-navigation {
	flex-wrap: wrap;
	}

	.tab-button {
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	}

	.tab-panel {
	padding: 1rem;
	}

	.champion-banner {
	flex-direction: column;
	padding: 1.5rem;
	}
}

.grilla-component {
  width: 100%;
  max-width: 900px; /* Or any width that works for your design */
}
</style>