import {defineStore} from "pinia";
import {API} from "@/services";


export const useAccountingStore = defineStore("accountingStore", () => {

    async function dispatchAddPayment(aPayment) {
        try {
            const {status} = await API.accountingRecord.addPayment(aPayment);
            if (status === 200) {
                return {
                    success: true,
                    content: null,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchGetPayments() {
        try {
            const {status, data} = await API.accountingRecord.getPayments();
            if (status === 200) {
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchGetDebtsTeam(idTeam, idTournament) {
        try {
            const {status, data} = await API.accountingRecord.getDebtsTeamTounament(idTeam, idTournament);
            if (status === 200) {
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchGetDebtsTournament(idTournament) {
        try {
            const {status, data} = await API.accountingRecord.getDebtsTournament(idTournament);
            if (status === 200) {
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    return {
        dispatchAddPayment,
        dispatchGetPayments,
        dispatchGetDebtsTeam,
        dispatchGetDebtsTournament
    }
}, );