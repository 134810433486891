<template>
  <div class="add-team-container">

    <div class="form-wrapper">
      <h2>Add Team</h2>
      <form @submit.prevent="saveTeam">
        <div class="form-group">
          <label for="teamName">Team Name:</label>
          <input :readonly="!!teamId" type="text" v-model="team.name" id="teamName" required/>
        </div>

        <div class="form-group">
          <label for="contactPerson">Contact Person:</label>
          <input type="text" v-model="team.contactPerson" id="contactPerson" />
        </div>

        <div class="form-group">
          <label for="contactNumber">Contact Number:</label>
          <input type="text" v-model="team.contactNumber" id="contactNumber" />
        </div>

        <div class="form-group">
          <label for="playerName">Player Name:</label>
          <div class="player-input-group">
            <input type="text" v-model="newPlayer" id="playerName"/>
            <button type="button" @click="addPlayer">Add Player</button>
          </div>
        </div>

        <ul class="player-list">
          <li v-for="(player, index) in team.players" :key="index">
            {{ player }}
			<span class="remove-icon" style="cursor: pointer;" @click="removePlayer(index)">🗑️</span>

          </li>
        </ul>

        <button type="submit" class="save-button">Save Team</button>
      </form>
    </div>

    <v-dialog v-model="showModal" max-width="400">
      <v-card>
        <v-card-title class="headline">{{ modalTitle }}</v-card-title>
        <v-card-text>{{ modalMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  <script>
  import {useTeamStore} from "@/store/team";

  export default {
	props: {
		teamId: {
			type: Number,
			default: null, // El teamId puede ser null si no estamos editando un equipo existente
		},
	},
    data() {
        return {
            team: {
                name: '',
                contactPerson: '',
                contactNumber: '',
                players: [],
				players_with_id : []
            },
            newPlayer: '',
            showModal: false,
            modalTitle: '',
            modalMessage: '',
        };
    },
	async created() {
		// Si teamId existe, obtenemos los detalles del equipo para editarlos
		if (this.teamId) {
			await this.fetchTeamDetails();
		}
	},
    methods: {
		async fetchTeamDetails() {
			try {
				const teamStore = useTeamStore();
				const response = await teamStore.dispatchGetTeamPlayers(this.teamId);
				if(response.success){
					this.team.name = response.content.name;
					this.team.players = response.content.players.map(player => player.name);
					this.team.players_with_id = response.content.players.map(player => {return {"id" : player.id , "name" : player.name};});
				}
			} catch (error) {
				console.log(error);
			}
			console.log("jugadores"  , this.players);
		},
      addPlayer() {
        const player = this.newPlayer.trim();
        if (player && !this.team.players.includes(player)) {
          this.team.players.push(player);
          this.newPlayer = '';
			this.team.players_with_id.push({id : null , name : player});
        }
      },
      async saveTeam() {
        try {
			const teamStore = useTeamStore();
			var response = null;
			if (this.teamId){
				response = await teamStore.dispatchUpdateTeam(this.team, this.teamId);
			}else{
				response = await teamStore.dispatchCreateTeam(this.team);
			}
			if (response.success) {
				this.modalTitle = 'Success';
				this.modalMessage = 'Team added successfully!';
				this.resetForm();
			} else {
				this.modalTitle = 'Error';
				this.modalMessage = 'Error adding team. Please try again.';
          }
        } catch (error) {
          this.modalTitle = 'Error';
          this.modalMessage = 'An error occurred. Please try again.';
        } finally {
          this.showModal = true;
        }
      },
      resetForm() {
        this.team.name = '';
        this.team.contactPerson = '';
        this.team.contactNumber = '';
        this.team.players = [];
      },
      closeModal() {
        this.showModal = false;
      },
		removePlayer(index) {
			this.team.players.splice(index, 1); // Eliminar el jugador por índice
			this.team.players_with_id.splice(index, 1);
		},
    },
  };
  </script>

  <style scoped>
  .add-team-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .form-wrapper {
    padding: 20px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .player-input-group {
    display: flex;
    align-items: center;
  }

  input[type="text"] {
    flex: 1;
    margin-right: 10px;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff; /* Matching the menu color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #0056b3; /* Darker shade of the menu color */
  }

  .save-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #007bff; /* Matching the menu color */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .save-button:hover {
    background-color: #0056b3; /* Darker shade of the menu color */
  }

  .player-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .player-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

.remove-icon {
	margin-left: 10px;
	color: red;
	cursor: pointer;
	font-size: 18px;
	transition: color 0.3s ease;
}

.remove-icon:hover {
	color: darkred;
}
  </style>
