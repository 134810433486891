<template>
<v-col offset-sm="0" offset-md="3" md="6" sm="4">
	<div class="stats-container">
	<!-- Goleadores -->
	<div class="stats-section">
		<div class="section-header">
		<h2>
			<span class="header-icon">⚽</span>
			Tabla de Goleadores
		</h2>
		</div>
		<div class="stats-list">
		<div 
			v-for="(scorer, index) in scorers" 
			:key="scorer.name" 
			class="stats-card"
			:class="{ 'top-three': index < 3 }"
		>
			<div class="position-badge" v-if="index < 3">
			{{ index + 1 }}
			</div>
			<div class="stats-content">
			<div class="player-info">
				<div class="player-name">{{ scorer.player_name }}</div>
				<div class="team-name">{{ scorer.team_name }}</div>
			</div>
			<div class="stats-value">
				<span class="stats-number">{{ scorer.goles }}</span>
				<span class="stats-label">goles</span>
			</div>
			</div>
		</div>
		</div>
	</div>

	<!-- Tarjetas Amarillas -->
	<div class="stats-section">
		<div class="section-header">
		<h2>
			<span class="header-icon">🟨</span>
			Tarjetas Amarillas
		</h2>
		</div>
		<div class="stats-list">
		<div 
			v-for="(player, index) in amonestados" 
			:key="player.name"
			class="stats-card yellow-card"
			:class="{ 'top-three': index < 3 }"
		>
			<div class="position-badge" v-if="index < 3">
			{{ index + 1 }}
			</div>
			<div class="stats-content">
			<div class="player-info">
				<div class="player-name">{{ player.player_name }}</div>
				<div class="team-name">{{ player.team_name }}</div>
			</div>
			<div class="stats-value">
				<span class="stats-number">{{ player.amarillas }}</span>
				<span class="stats-label">amarillas</span>
			</div>
			</div>
		</div>
		</div>
	</div>

	<!-- Tarjetas Rojas -->
	<div class="stats-section">
		<div class="section-header">
		<h2>
			<span class="header-icon">🟥</span>
			Tarjetas Rojas
		</h2>
		</div>
		<div class="stats-list">
		<div 
			v-for="(player, index) in rojas" 
			:key="player.name"
			class="stats-card red-card"
			:class="{ 'top-three': index < 3 }"
		>
			<div class="position-badge" v-if="index < 3">
			{{ index + 1 }}
			</div>
			<div class="stats-content">
			<div class="player-info">
				<div class="player-name">{{ player.player_name }}</div>
				<div class="team-name">{{ player.team_name }}</div>
			</div>
			<div class="stats-value">
				<span class="stats-number">{{ player.rojas }}</span>
				<span class="stats-label">rojas</span>
			</div>
			</div>
		</div>
		</div>
	</div>
	</div>
</v-col>
</template>

<script>
import { useTournamentStore } from "@/store/tournament";

export default {
name: 'PlayerStatistics',
props: {
	idTournament: {
	type: String,
	required: true
	}
},
data() {
	return {
	tournamentStore: useTournamentStore(),
	};
},
computed: {
	scorers() {
	return this.tournamentStore.bestScorerOfTournament;
	},
	amonestados() {
	return this.tournamentStore.yellowsOfTournament;
	},
	rojas() {
	return this.tournamentStore.redsOfTournament;
	}
}
};
</script>

<style scoped>
.stats-container {
padding: 20px;
background: #f8f9fa;
border-radius: 16px;
box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.stats-section {
margin-bottom: 32px;
background: white;
border-radius: 12px;
padding: 20px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.section-header {
margin-bottom: 24px;
text-align: center;
}

.section-header h2 {
font-size: 1.5rem;
color: #2c3e50;
font-weight: 600;
display: flex;
align-items: center;
justify-content: center;
gap: 8px;
}

.header-icon {
font-size: 1.8rem;
}

.stats-list {
display: flex;
flex-direction: column;
gap: 12px;
}

.stats-card {
position: relative;
background: #f8fafc;
border-radius: 10px;
padding: 16px;
transition: all 0.3s ease;
border: 1px solid #e2e8f0;
}

.stats-card:hover {
transform: translateY(-2px);
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.stats-content {
display: flex;
justify-content: space-between;
align-items: center;
}

.player-info {
flex-grow: 1;
}

.player-name {
font-size: 1.1rem;
font-weight: 600;
color: #2c3e50;
margin-bottom: 4px;
}

.team-name {
font-size: 0.9rem;
color: #64748b;
}

.stats-value {
display: flex;
flex-direction: column;
align-items: flex-end;
min-width: 80px;
}

.stats-number {
font-size: 1.5rem;
font-weight: 700;
color: #2c3e50;
}

.stats-label {
font-size: 0.8rem;
color: #64748b;
}

.position-badge {
position: absolute;
top: -10px;
left: -10px;
width: 30px;
height: 30px;
background: #3498db;
color: white;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-weight: 700;
font-size: 0.9rem;
border: 2px solid white;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-three {
background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
border: 2px solid #e2e8f0;
}

.top-three:nth-child(1) .position-badge {
background: #f1c40f;
}

.top-three:nth-child(2) .position-badge {
background: #bdc3c7;
}

.top-three:nth-child(3) .position-badge {
background: #d35400;
}

.yellow-card .stats-number {
color: #f1c40f;
}

.red-card .stats-number {
color: #e74c3c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
.stats-container {
	padding: 12px;
}

.stats-section {
	padding: 16px;
}

.section-header h2 {
	font-size: 1.3rem;
}

.player-name {
	font-size: 1rem;
}

.stats-number {
	font-size: 1.3rem;
}
}

/* Animations */
@keyframes slideIn {
from {
	opacity: 0;
	transform: translateY(20px);
}
to {
	opacity: 1;
	transform: translateY(0);
}
}

.stats-card {
animation: slideIn 0.3s ease-out forwards;
}

.stats-card:nth-child(2) {
animation-delay: 0.1s;
}

.stats-card:nth-child(3) {
animation-delay: 0.2s;
}
</style>