<script setup>
import {computed, onMounted, ref} from "vue";
import { useRouter } from "vue-router";
import {useAccountingStore} from "@/store/accountingRecord";

const router = useRouter();

// Reactive state
const paymentsBack = ref();
onMounted( async () => {
  const payments = useAccountingStore();
  const response = await payments.dispatchGetPayments();
  if(response.success){
    paymentsBack.value = response.content;
  }
});

// const tableOptions = reactive({
//   itemsPerPage: 10,
//   loading: false,
//   sortBy: [{ key: 'nameTeam', order: 'asc' }],
//   tableKey: new Date().getUTCMilliseconds(),
//   page: 1,
//   pageCount: Math.ceil(paymentsBack.value.length / 10)
// });

// Table headers with improved configuration
const headerPayment = ref([
  {
    title: 'Nombre Equipo',
    align: 'center',
    sortable: true,
    key: 'nameTeam',
  },
  {
    title: 'Tipo de pago',
    align: 'start',
    sortable: true,
    key: 'paymentType',
  },
  {
    title: 'Torneo',
    align: 'center',
    sortable: true,
    key: 'tournamentName',
  },
  {
    title: 'Monto',
    align: 'center',
    sortable: true,
    key: 'amount',
  },
  {
    title: 'Fecha',
    align: 'center',
    sortable: true,
    key: 'payment_date',
  },
  {
    title: 'Observaciones',
    align: 'center',
    sortable: false,
    key: 'observations',
  },
]);

// Methods
const addNewPayment = () => {
  router.push({
    name: 'addPayment',
  });
};


const formatDate = (isoDate) => {
  const date = new Date(isoDate)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${day}-${month}-${year} ${hours}:${minutes}`
}

const filteredHeaders = computed(() => {
  return headerPayment.value.filter(header => header.key !== 'is_full_paid')
})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-card class="custom-card">
      <!-- Header Section -->
      <v-card-title class="header-section px-6 py-4">
        <div class="d-flex flex-wrap justify-space-between align-center">
          <div class="title-section">
            <h2 class="text-h5 font-weight-bold mb-1">Control de movimientos</h2>
            <span class="text-subtitle-1 text-medium-emphasis">
              Gestión de movimientos de la organización
            </span>
          </div>

          <v-col cols="12" sm="6" class="text-sm-end mt-4 mt-sm-0" offset-md="7">
            <div class="actions-section d-inline-flex">
<!--              <v-btn-->
<!--                  color="primary"-->
<!--                  prepend-icon="mdi-plus"-->
<!--                  class="mr-2"-->
<!--                  elevation="2"-->
<!--                  @click="addNewPayment"-->
<!--              >-->
<!--                Nuevo Gasto-->
<!--              </v-btn>-->
              <v-btn
                  color="primary"
                  prepend-icon="mdi-plus"
                  class="mr-2"
                  elevation="2"
                  @click="addNewPayment"
              >
                Nuevo Cobro
              </v-btn>
<!--              <v-btn-->
<!--                  variant="outlined"-->
<!--                  prepend-icon="mdi-refresh"-->
<!--                  @click="refreshData"-->
<!--                  :loading="tableOptions.loading"-->
<!--                  :disabled="tableOptions.loading"-->
<!--              >-->
<!--                Actualizar-->
<!--              </v-btn>-->
            </div>
          </v-col>
        </div>
      </v-card-title>

      <v-divider></v-divider>

      <!-- Table Section -->
      <v-card-text class="pa-6">
        <v-data-table
            :headers="filteredHeaders"
            :items="paymentsBack"
            :search="''"
            hover
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center">{{ item.equipo }}</td>
              <td>{{ item.id_payment_type }}</td>
              <td>{{ item.torneo }}</td>
              <td class="text-end">${{ item.amount }}</td>
              <td class="text-center">{{ formatDate(item.created_at) }}</td>
              <td>{{ item.observations === null ? '-' : item.observations}}</td>
            </tr>
          </template>

          <!-- No data template -->
          <template v-slot:no-data>
            <v-alert
                type="info"
                class="ma-2"
                variant="tonal"
            >
              No hay pagos registrados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>
.custom-card {
  border-radius: 12px;
  overflow: hidden;
}

.header-section {
  background-color: rgb(var(--v-theme-background));
}

.title-section h2 {
  color: rgb(var(--v-theme-on-background));
  letter-spacing: 0.0125em;
}

.actions-section {
  gap: 12px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .header-section {
    padding: 16px !important;
  }

  .title-section {
    margin-bottom: 16px;
  }

  .actions-section {
    width: 100%;
    justify-content: flex-start;
  }

  .actions-section .v-btn {
    flex: 1;
  }
}
</style>