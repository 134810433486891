<template>
  <v-container fluid class="tournament-league">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <h1 class="text-h3 font-weight-bold gradient-text mb-8">Liga</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <div v-for="(matchesByDate, date) in matches" :key="date" class="date-group">
          <!-- Date Header -->
          <v-row class="round-separator">
            <v-col>
              <h2 class="text-h5 text-center round-title">
                <v-icon icon="mdi-calendar" class="mr-2" />
                Fecha n° {{ date.replace(/\D/g, "") }}
              </h2>
              <div class="separator-line"></div>
            </v-col>
          </v-row>

          <!-- Matches -->
          <match-row
              v-for="(match, index) in matchesByDate"
              :key="`${date}-${index}`"
              :match="match"
              :is-able="isAble"
              :tournament-details="tournamentDetails"
              @match-updated="$emit('refresh-matches')"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
/* eslint-disable */
import { computed } from 'vue';
import { useAuthStore } from "@/store/auth";
import { useTournamentDetailsStore } from "@/store/tournamentDetails";
import MatchRow from "@/components/MatchRow.vue";

// Props
const props = defineProps({
  matches: {
    type: Object,
    required: true
  }
});


// Store access
const authStore = useAuthStore();
const tournamentDetails = useTournamentDetailsStore();

// Computed
const isAble = computed(() => authStore.isAble === 1);
</script>

<style scoped>
.tournament-league {
  padding: 2rem 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.gradient-text {
  background: linear-gradient(45deg, #2196F3, #00BCD4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.date-group {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.round-separator {
  margin: 1rem 0;
}

.round-title {
  color: #1976D2;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.separator-line {
  height: 2px;
  background: linear-gradient(90deg, transparent, #1976D2, transparent);
  margin-top: 0.5rem;
}

@media (max-width: 960px) {
  .gradient-text {
    font-size: 2rem;
  }

  .round-title {
    font-size: 1.2rem;
  }

  .date-group {
    margin: 1rem;
    padding: 0.5rem;
  }
}

@media (max-width: 600px) {
  .gradient-text {
    font-size: 1.5rem;
  }

  .date-group {
    margin: 0.5rem;
    padding: 0.5rem;
  }
}
</style>