<template>
        
    <!-- Tabla de Posiciones -->
    <div class="league-table-container">
        <h2 class="section-title">Tabla de la liga</h2>
        <v-data-table
        :headers="headers"
        :items="groups"
        :loading="tableOptions.loading"
        density="comfortable"
        class="elevation-2 rounded-lg"
        :items-per-page="-1"
        hide-default-footer
        >
        <template v-slot:header="{ header }">
            <tr class="header-row">
            <th v-for="h in header" :key="h.key" class="header-cell">
                {{ h.title }}
            </th>
            </tr>
        </template>

        <template v-slot:item="{ item, index }">
            <tr 
            :class="[
                'table-row',
                { 'qualified': isClassified(item.position) },
                { 'row-alternate': index % 2 === 0 }
            ]"
            >
            <td class="position-cell">{{ item.position }}</td>
            <td class="team-cell">
                <span class="team-name">{{ item.name }}</span>
            </td>
            <td class="stats-cell"><b>{{ item.stats?.points || 0 }}</b></td>
            <td class="stats-cell">{{ item.stats?.pj || 0 }}</td>
            <td class="stats-cell">{{ item.stats?.partidos_ganados || 0 }}</td>
            <td class="stats-cell">{{ item.stats?.partidos_empatados || 0 }}</td>
            <td class="stats-cell">{{ item.stats?.partidos_perdidos || 0 }}</td>
            <td v-show="!isDesktop" class="stats-cell">{{ item.stats?.goles || 0 }}</td>
            <td v-show="!isDesktop" class="stats-cell">{{ item.stats?.goles_contra || 0 }}</td>
            <td v-show="!isDesktop" class="stats-cell">
                {{ item.stats ? item.stats.goles - item.stats.goles_contra : 0 }}
            </td>
            </tr>
        </template>
        </v-data-table>
    </div>
</template>


<script>
/* eslint-disable */

import { useDisplay } from 'vuetify';
import { useTournamentDetailsStore } from "@/store/tournamentDetails";

export default {
	name: 'TournamentTabs',

	components: {

	},
	props: {
		info: { required: true },
	},
	setup() {
		const { isDesktop } = useDisplay();
		return { isDesktop };
	},
	data() {
		return {
			tableOptions: {
                loading: false,
                sortBy: [{ key: 'name', order: 'asc' }],
                tableKey: new Date().getUTCMilliseconds(),
			},
			headers: [
                { key: 'position', title: 'Pos', align: 'center', width: '50px' },
                { key: 'name', title: 'Equipo', align: 'left' },
                { key: 'points', title: 'Pts', align: 'center', width: '60px' },
                { key: 'pj', title: 'PJ', align: 'center', width: '60px' },
                { key: 'pg', title: 'PG', align: 'center', width: '60px' },
                { key: 'pe', title: 'PE', align: 'center', width: '60px' },
                { key: 'pp', title: 'PP', align: 'center', width: '60px' },
                { key: 'gf', title: 'GF', align: 'center', width: '60px', class: 'd-none d-lg-table-cell' },
                { key: 'gc', title: 'GC', align: 'center', width: '60px', class: 'd-none d-lg-table-cell' },
                { key: 'dif', title: 'DIF', align: 'center', width: '60px', class: 'd-none d-lg-table-cell' }
			],
			groups: []
		};
	},
	methods: {
		isClassified(position) {
			return this.classified && position <= this.classified;
		}
	},
	mounted() {
		const detailsStore = useTournamentDetailsStore();
		this.groups = this.info;
		
	}
};
</script>

<style scoped>
.tournament-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 1.5rem;
	overflow: visible;
}

.tabs-navigation {
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	background: linear-gradient(to right, #1a237e, #0d47a1);
	padding: 1rem;
	border-radius: 12px;
	margin-bottom: 2rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.tab-button {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.75rem 1.5rem;
	border: none;
	border-radius: 8px;
	background: transparent;
	color: white;
	cursor: pointer;
	transition: all 0.3s ease;
	font-weight: 500;
}

.tab-button:hover {
	background: rgba(255, 255, 255, 0.1);
	transform: translateY(-1px);
}

.tab-button.active {
	background: #2196f3;
	box-shadow: 0 2px 4px rgba(33, 150, 243, 0.3);
}

.tab-icon {
	font-size: 1.25rem;
}

.content-wrapper {
	background: transparent;
	border-radius: 12px;
	overflow: visible;
	box-shadow: none;
}

.tab-panel {
	padding: 2rem;
	height: auto;
	min-height: 100%;
	overflow: visible;
	position: relative;
	background: transparent;
	box-shadow: none;
}

.champion-banner {
	background: linear-gradient(135deg, #ffd700, #ffa000);
	border-radius: 12px;
	padding: 2rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	color: white;
	text-align: center;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.trophy-icon {
	font-size: 2.5rem;
}

.champion-title {
	font-size: 1.5rem;
	margin: 0;
}

.champion-name {
	display: block;
	font-size: 2rem;
	font-weight: 700;
	margin-top: 0.5rem;
}

.section-title {
	font-size: 1.5rem;
	color: #1a237e;
	margin-bottom: 1.5rem;
	text-align: center;
}

.league-table-container {
	background: white;
	border-radius: 12px;
	overflow: hidden;
	margin-bottom: 2rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.header-row {
	background: #1a237e !important;
}

.header-cell {
	color: white !important;
	font-weight: 600;
	padding: 1rem !important;
}

.table-row {
	transition: background-color 0.2s ease;
}

.table-row:hover {
	background-color: #f5f5f5;
}

.qualified {
	background-color: #e3f2fd;
}

.row-alternate {
	background-color: #fafafa;
}

.position-cell {
	font-weight: 700;
	color: #1a237e;
}

.team-cell {
	font-weight: 500;
}

.stats-cell {
	text-align: center;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.v-data-table__wrapper {
	margin-bottom: 0 !important;
}

@media (max-width: 768px) {
	.tournament-container {
	padding: 1rem;
	}

	.tabs-navigation {
	flex-wrap: wrap;
	}

	.tab-button {
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	}

	.tab-panel {
	padding: 1rem;
	}

	.champion-banner {
	flex-direction: column;
	padding: 1.5rem;
	}
}
</style>