<template>
    <v-row class="tabs">
		<button
			v-for="(tab, index) in headers"
			:key="index"
			:class="{ active: selectedTab === index }"
			@click="selectedTab = index; "
			class="tab-button"
		>
			{{ tab }}
		</button>
    </v-row>

	<div class="tournament">
		<div class="round-container">
			<div class="tab-content">
				<div v-for="(tab, index) in headers" :key="index" v-show="selectedTab === index" >
					<table style="border-spacing: 10px;">
						<thead>
							<tr>
								<th v-for="col in [index + 1, index + 2]" :key="col">{{ getHeader(col) }}</th>
							</tr>
						</thead>
						<tbody>
							<v-row v-for="(row, rowIndex) in rows" :key="rowIndex" class="text-center">
								<v-col v-for="col in [index + 1, index + 2]" :key="col" cols="6">
									<!-- Guardamos el resultado de getMatch en una variable local -->
									<template v-if="(match = getMatch(row, col, [index + 1, index + 2], index + 1))">
										<div class="match" :id="'match-' + match.id_match">
											<div v-if="match.id_team_1 == match.winner && match.winner != null " class="team-score winner match-team">
												{{ match.name_team_1 === '' || match.name_team_1 == null ? (col == 1 ? 'Bye' : 'Próximamente') : match.name_team_1  }}
											</div>
											<div v-else class="team-score nowinner">
												{{ match.name_team_1 === ''  || match.name_team_1 == null ? col == 1 ? 'Bye' : 'Próximamente' : match.name_team_1}}
											</div>

											<div class="match-divider"></div>

											<div v-if="match.id_team_2 == match.winner && match.winner != null"  class="team-score winner match-team">
												{{ match.name_team_2 === ''  || match.name_team_2 == null ? col == 1 ? 'Bye' : 'Próximamente' : match.name_team_2  }}
											</div>
											<div v-else class="team-score nowinner">
												{{ match.name_team_2 === ''  || match.name_team_2 == null ? col == 1 ? 'Bye' : 'Próximamente' : match.name_team_2  }}
											</div>
										</div>
									</template>
									<!-- Si match es null o undefined, muestra el fallback -->
									<div v-else class="match-sinpadding" :id="'matchn-' + row"></div>
								</v-col>
							</v-row>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: ['idT', 'info', 'matches', 'champion', 'sport'],

	data() {
		return {
			fixture: [],
			rounds: 0,
			margin: 0,
			finalMargin: 0,
			rows: 0,
			columns: 0,
			headers: [],
			selectedTab: 0,
			activeTab: 0,
		};
	},
	mounted() {
		if (! this.sport == "futbol"){
			this.tabs = ['League Table', 'Grilla de partidos', 'Teams'];
		}
		this.fetchFixtureData();
	},
	methods: {
		fetchFixtureData() {
			try {
				console.log("partidos");
				console.log(this.matches);
				this.rounds = Math.log2(this.matches.length + 1);
				this.fixture = [];
				var fixture_i = [];
				var matches_by_round = 2 ** (this.rounds - 1);
				this.rows = 2 ** (this.rounds - 1) * 2 - 1;
				this.matches.forEach(match => {
					if (fixture_i.length < matches_by_round) {
						fixture_i.push(match);
					} else {
						this.fixture.push(fixture_i);
						fixture_i = [match];
						matches_by_round = matches_by_round / 2;
						console.log(fixture_i);
					}
				});
				this.fixture.push(fixture_i);

				console.log("fixture");
				console.log(this.fixture);

				if (this.rounds == 1) {
					this.headers = ["Final"];
				}
				if (this.rounds == 2) {
					this.headers = ["Semi final", "Final"];
				}
				if (this.rounds == 3) {
					this.headers = ["Cuartos de final", "Semi final", "Final"];
				}
				if (this.rounds == 4) {
					this.headers = ["Octavos de final", "Cuartos de final", "Semi final", "Final"];
				}
				if (this.rounds == 5) {
					this.headers = ["1 ronda", "Octavos de final", "Cuartos de final", "Semi final", "Final"];
				}
				if (this.rounds == 6) {
					this.headers = ["1 ronda", "2 ronda", "Octavos de final", "Cuartos de final", "Semi final", "Final"];
				}
				if (this.rounds == 7) {
					this.headers = ["1 ronda", "2 ronda", "3 ronda", "Octavos de final", "Cuartos de final", "Semi final", "Final"];
				}
			} catch (error) {
				console.error("Error al obtener los datos del fixture:", error);
			}

		},

		checkAndReturnMatch(row, col, ls) {
			row = row - 1; 
			col = col - 1;
			console.log("probando", row, col, ls, this.fixture);
			console.log(this.rows);
			if (ls[0] == col+1){
				return row % 2 == 0; 
			}
			return Number.isInteger((row -1) / 4); // tiene q tener la pinta de x*4 + 1
		},
		getHeader(col) {
			return this.headers[col - 1];
		},
		getMatch(row, col, ls , first_column) {
			row = row - 1; 
			col = col - 1;
			if (col == this.rounds-1){
				// estoy en la final , pero si es la final pero no la de q esta sola , sino 
				// la q acompaña a la semi ?? 
				if (col == first_column){
					if (row == 1){
						// devuelvo la final 
						return this.fixture[col][0];
					}
					else{
						return null;
					}
				}
				else{
					if (row == 0){
						// devuelvo la final 
						return this.fixture[col][0];
					}
					else{
						return null;
					}
				}


			}
			try {
				console.log("probando2", row, col, ls, this.fixture);
				
				if (ls[0] == col+1){
					if (row / 2 >= this.fixture[col].length){
						return null;
					}
					return this.fixture[col][row / 2];
				}
				else{
					if (((row -1) / 4) >= this.fixture[col].length){
						return null;
					}
					return this.fixture[col][(row -1) / 4];
				}

				
			}catch (error) {
				return null;
			}
		},


		addConnections(instance) {
			instance.connect({
				source: `matchn-1`,
				target: `match-0-1`,
				anchors: ['Right', 'Left'],
				connector: ['Flowchart'],
				paintStyle: { stroke: '#5c96bc', strokeWidth: 2 },
				endpoint: ['Dot', { radius: 5 }],
				overlays: [['Arrow', { width: 10, length: 10, location: 1 }]],
			});


			this.fixture.forEach((rondas, roundIndex) => {
				var i = 0; 
				rondas.forEach((match, roundMatch) => {
					if (i == 0){
						instance.connect({
							source: `matchn-1`,
							target:`match-${match.id_match}-` + (roundIndex + 1),
							anchors: ['Right', 'Left'],
							connector: ['Flowchart'],
							paintStyle: { stroke: 'white', strokeWidth: 0 },
							endpoint: ['Dot', { radius: 5 }],
							overlays: [['Arrow', { width: 10, length: 10, location: 1 }]],
						});
					}
					i = i + 1;
					console.log(roundIndex);
					console.log(roundMatch);
					const sourceId = `match-${match.id_match}-` + (roundIndex + 1);
					const targetId = `match-${match.id_next_match}-` + (roundIndex + 1);

					instance.connect({
						source: sourceId,
						target: targetId,
						anchors: ['Right', 'Left'],
						connector: ['Flowchart'],
						paintStyle: { stroke: '#5c96bc', strokeWidth: 2 },
						endpoint: ['Dot', { radius: 5 }],
						overlays: [['Arrow', { width: 10, length: 10, location: 1 }]],
					});
				});
			});
			document.querySelector('.jtk-connector').remove();
			document.querySelector('circle').remove();
		},
		handleTabClick() {
			this.$nextTick(() => {
				//this.initJsPlumb();
			});
		},
	},
};
</script>

<style scoped>


* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}


.tournament {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.round-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.match {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px; /* Reducido el padding */
  width: 250px;
  margin: 20px auto;
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin-right: 30px; /* Ajusta la distancia entre las columnas */

}

.match-team {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  padding: 5px 10px; /* Reducido el padding dentro de cada equipo */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.match-divider {
  width: 100%;
  border-top: 1px solid #ddd;
  margin: 5px 0; /* Reducido el margen entre el separador y los equipos */
}

.match-vs {
  font-size: 16px;
  color: #777;
  margin: 10px 0;
}

.match-sinpadding {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 40px;
}

.team-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
}

.team-score.winner {
  background-color: #d4edda;
  border: 2px solid #c3e6cb;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.match-divider {
  width: 100%;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background-color: #0A374D; /* Azul oscuro */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tab-button {
    padding: 12px 24px;
    margin: 0 10px;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
}

.tab-button.active {
    background-color: #14A38B; /* Verde pastel */
    color: white;
    border: 2px solid #14A38B; /* Verde pastel */
}

.tab-button:hover {
    background-color: #096B80; /* Azul intermedio para hover */
}

.pre-classification {
  font-size: 0.8em; /* Esto hace que el texto sea más pequeño */
  color: gray; /* Opcional, para darle un estilo más suave */
}


</style>
