<template>
<v-col offset-sm="0" offset-md="3" md="6" sm="4">
  <div class="teams-container">
    <div class="teams-header">
      <h2>{{ title }}</h2>
      <div class="search-box">
        <input 
          v-model="searchQuery" 
          type="text" 
          placeholder="Buscar equipo..."
          class="search-input"
        >
      </div>
    </div>

    <transition-group 
      name="team-list" 
      tag="ul" 
      class="team-list"
    >
      <template v-if="existTeams">
        <li 
          v-for="(team, index) in filteredTeams" 
          :key="team.id || index"
          class="team-item"
          @click="goToTeamDetails(team.id)"
        >
          <div class="team-content">
            <div class="team-avatar">
              {{ getInitials(team.name) }}
            </div>
            <div class="team-info">
              <span class="team-name">{{ team.name }}</span>
              <span v-if="team.players" class="team-players">
                {{ team.players }} jugadores
              </span>
            </div>
            <div class="team-arrow">
              →
            </div>
          </div>
        </li>
      </template>
    </transition-group>

    <div v-if="loading" class="loading-container">
      <div class="loading-spinner"></div>
      <span>Cargando equipos...</span>
    </div>

    <div v-if="!loading && !existTeams" class="empty-state">
      <span class="empty-icon">🏆</span>
      <p>No hay equipos disponibles</p>
      <small>Los equipos aparecerán aquí cuando se añadan al torneo</small>
    </div>
  </div>
</v-col>
</template>

<script>
import { useTeamStore } from "@/store/team";
import { useTournamentStore } from "@/store/tournament";
import {useTournamentDetailsStore} from "@/store/tournamentDetails";

export default {
name: 'ShowTeamsOfTournament',

props: {
  tournamentId: {
    type: String,
    required: false
  }
},
data() {
  return {
    loading: true,
    menu: false,
    searchQuery: '',
    teamsStore: useTeamStore(),
    teamTournament: useTournamentStore()
  };
},
computed: {
  title() {
    return this.tournamentId ? 'Equipos del Torneo' : 'Todos los Equipos';
  },
  teams() {
    return this.tournamentId 
      ? this.teamTournament.teamsOfTournament
      : this.teamsStore.teams;
  },
  filteredTeams() {
    return this.teams.filter(team => 
      team.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  },
  existTeams() {
    return this.teams.length > 0;
  },
},
mounted() {
  this.fetchTeams();
  if(this.tournamentId === undefined || this.tournamentId === null) {
    const tournamentDetails = useTournamentDetailsStore();
    tournamentDetails.clearTournament();
  }
  this.loading = false;
},
methods: {
  getInitials(name) {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  },
  goToTeamDetails(teamId) {
    this.$router.push({
      name: 'ShowTeam', 
      params: { teamId }
    });
  },
  async fetchTeams() {
    try {
      const response = await this.teamsStore.dispatchGetTeams();
      if (!response.success) {
        throw new Error("Failed to fetch teams");
      }
    } catch (error) {
      console.error("Error al obtener equipos del torneo:", error);
    }
  }
}
};
</script>

<style scoped>
.teams-container {
background: white;
border-radius: 12px;
box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
padding: 24px;
margin: 20px 0;
}

.teams-header {
margin-bottom: 24px;
}

.teams-header h2 {
color: #2c3e50;
font-size: 1.8rem;
font-weight: 600;
margin-bottom: 16px;
text-align: center;
}

.search-box {
margin-bottom: 20px;
}

.search-input {
width: 100%;
padding: 12px 16px;
border: 2px solid #e2e8f0;
border-radius: 8px;
font-size: 1rem;
transition: all 0.3s ease;
}

.search-input:focus {
outline: none;
border-color: #3498db;
box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.team-list {
list-style: none;
padding: 0;
margin: 0;
}

.team-item {
background: #f8fafc;
border-radius: 10px;
margin-bottom: 12px;
transition: all 0.3s ease;
overflow: hidden;
cursor: pointer;
}

.team-item:hover {
transform: translateY(-2px);
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
background: #fff;
}

.team-item.is-tennis {
cursor: default;
}

.team-content {
display: flex;
align-items: center;
padding: 16px;
gap: 16px;
}

.team-avatar {
width: 48px;
height: 48px;
background: #3498db;
color: white;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-weight: 600;
font-size: 1.2rem;
flex-shrink: 0;
}

.team-info {
flex-grow: 1;
}

.team-name {
display: block;
font-weight: 600;
color: #2c3e50;
font-size: 1.1rem;
margin-bottom: 4px;
}

.team-players {
font-size: 0.9rem;
color: #64748b;
}

.team-arrow {
color: #64748b;
font-size: 1.2rem;
transition: transform 0.3s ease;
}

.team-item:hover .team-arrow {
transform: translateX(4px);
color: #3498db;
}

.loading-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 40px 0;
color: #64748b;
}

.loading-spinner {
width: 40px;
height: 40px;
border: 3px solid #e2e8f0;
border-top-color: #3498db;
border-radius: 50%;
animation: spin 1s linear infinite;
margin-bottom: 16px;
}

.empty-state {
text-align: center;
padding: 40px 0;
color: #64748b;
}

.empty-icon {
font-size: 3rem;
margin-bottom: 16px;
display: block;
}

.empty-state p {
font-size: 1.1rem;
margin-bottom: 8px;
color: #2c3e50;
}

.empty-state small {
font-size: 0.9rem;
}

/* Animations */
@keyframes spin {
to {
  transform: rotate(360deg);
}
}

.team-list-enter-active,
.team-list-leave-active {
transition: all 0.5s ease;
}

.team-list-enter-from,
.team-list-leave-to {
opacity: 0;
transform: translateY(30px);
}

.team-list-move {
transition: transform 0.5s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
.teams-container {
  padding: 16px;
  margin: 12px;
}

.teams-header h2 {
  font-size: 1.5rem;
}

.team-content {
  padding: 12px;
}

.team-avatar {
  width: 40px;
  height: 40px;
  font-size: 1rem;
}
}
</style>