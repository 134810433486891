<script setup>
import { onMounted, ref, computed } from "vue";
import { useSuspensionStore } from "@/store/suspension";
import { useTournamentDetailsStore } from "@/store/tournamentDetails";

const search = ref('');
const loading = ref(true);
const suspensions = ref([]);
const totalSuspensions = computed(() => suspensions.value.length);

const headerSuspensions = [
  {
    title: 'Equipo',
    align: 'center',
    sortable: true,
    key: 'equipos',
    width: '25%',
  },
  {
    title: 'Jugador',
    align: 'center',
    sortable: true,
    key: 'jugador',
    width: '30%',
  },
  {
    title: 'Fechas Suspendido',
    align: 'center',
    sortable: true,
    key: 'suspension_por_los_proximos_n_partidos',
    width: '20%',
  },
  {
    title: 'Estado',
    align: 'center',
    sortable: true,
    key: 'estado',
    width: '25%',
  }
];

const getSuspensionStatus = (matches) => {
  if (matches <= 0) return {text: 'Cumplida', color: 'success'};
  if (matches === 1) return {text: 'Último Partido', color: 'warning'};
  return {text: 'En Curso', color: 'error'};
};

const fetchSuspensions = async () => {
  try {
    loading.value = true;
    const suspensionStore = useSuspensionStore();
    const tournamentStore = useTournamentDetailsStore();
    const response = await suspensionStore.dispatchGetSuspension(tournamentStore.idTournament);

    if (response.success) {
      suspensions.value = response.content.map(suspension => ({
        ...suspension,
        estado: getSuspensionStatus(suspension.suspension_por_los_proximos_n_partidos)
      }));
    }
  } catch (error) {
    console.error('Error cargando sanciones:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(fetchSuspensions);
</script>

<template>
  <v-container fluid class="pa-4">
    <v-card class="suspension-card elevation-3">
      <!-- Header Section -->
      <v-card-title class="header-section px-6 py-4">
        <div class="d-flex flex-wrap justify-space-between align-center">
          <div class="title-section">
            <h2 class="text-h5 font-weight-bold mb-1">Registro de Sanciones</h2>
            <span class="text-subtitle-2 text-medium-emphasis">
              Total de sanciones: {{ totalSuspensions }}
            </span>
          </div>

          <!-- Search Field -->
          <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              density="comfortable"
              variant="outlined"
              class="search-field mt-2 mt-sm-0"
              style="max-width: 300px"
          />
        </div>
      </v-card-title>

      <v-divider/>

      <!-- Table Section -->
      <v-card-text class="pa-6">
        <v-data-table
            :headers="headerSuspensions"
            :items="suspensions"
            :search="search"
            :loading="loading"
            hover
            class="suspension-table"
            :items-per-page="10"
            :footer-props="{
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': 'Registros por página:'
          }"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center font-weight-medium">{{ item.equipos }}</td>
              <td>
                <div class="d-flex align-center">
<!--                  <v-avatar size="32" class="mr-2">-->
<!--                    <v-img-->
<!--                        :src="`/api/placeholder/32/32`"-->
<!--                        alt="Player"-->
<!--                    />-->
<!--                  </v-avatar>-->
                  {{ item.jugador }}
                </div>
              </td>
              <td class="text-center">
                <v-chip
                    :color="item.suspension_por_los_proximos_n_partidos > 2 ? 'error' : 'warning'"
                    size="small"
                    class="font-weight-medium"
                >
                  {{ item.suspension_por_los_proximos_n_partidos }}
                </v-chip>
              </td>
              <td class="text-center">
                <v-chip
                    :color="item.estado.color"
                    size="small"
                    class="font-weight-medium"
                >
                  {{ item.estado.text }}
                </v-chip>
              </td>
            </tr>
          </template>

          <!-- Loading template -->
          <template v-slot:loading>
            <v-skeleton-loader
                type="table-row@6"
                class="pa-4"
            />
          </template>

          <!-- No data template -->
          <template v-slot:no-data>
            <v-alert
                type="info"
                class="ma-2"
                variant="tonal"
                icon="mdi-information"
            >
              No hay sanciones registradas en el torneo actual
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>
.suspension-card {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.suspension-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.header-section {
  background-color: rgb(var(--v-theme-surface));
}

.search-field {
  transition: all 0.3s ease;
}

.search-field:focus-within {
  transform: translateY(-1px);
}

.suspension-table {
  border-radius: 8px;
}

@media (max-width: 600px) {
  .title-section {
    margin-bottom: 16px;
  }

  .search-field {
    width: 100%;
    max-width: none !important;
  }
}
</style>