<template>
    <div>
      <h2>Registro de usuario</h2>
      <form @submit.prevent="registerUser">
        <div>
          <label for="name">Nombre</label>
          <input type="text" v-model="form.name" required />
        </div>
        <div>
          <label for="email">Email</label>
          <input type="email" v-model="form.email" required />
        </div>
        <div>
          <label for="password">Contraseña</label>
          <input type="password" v-model="form.password" required />
        </div>
        <div>
          <label for="password_confirmation">Confirmar Contraseña</label>
          <input type="password" v-model="form.password_confirmation" required />
        </div>
        <button type="submit">Registrarse</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        form: {
          name: "",
          email: "",
          password: "",
          password_confirmation: "",
        },
      };
    },
    methods: {
      async registerUser() {
        try {
          const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + "register", this.form);
          console.log(response.data);
          alert("Registro exitoso");
        } catch (error) {
          console.error(error);
          alert("Hubo un error en el registro");
        }
      },
    },
  };
  </script>
  